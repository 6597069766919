import { useEffect } from 'react';
import { useState } from 'react';
import { loginAndroid } from '../cloud/CloudUtils';
function AccountPage() {
    const [firebaseToken, setFirebaseToken] = useState("");

    useEffect(() => {
        loginAndroid().then((token) => {
            setFirebaseToken(token);
        })
    }, []);

    return (
        <div>firebaseToken:<br></br>{firebaseToken}</div>
    );
}
export default AccountPage;