import missed from '../assets/missed.png';
import outgoing from '../assets/outgoing.png';
import incoming from '../assets/incoming.png';
import defaultContactImage from '../assets/ic_account.png';
export default function CallLogListItem(props) {
    var padding = 10;
    var callIcon;
    var profilePhoto;
    switch (props.listObject.getCallType()) {
        case '3':
        case '7':
        case '5':
            callIcon = missed;
            break;
        case '2':
            callIcon = outgoing;
            break;
        case '1':
            callIcon = incoming;
            break;
        default:
    }
    if (props.listObject.getPhoto() !== null) {
        profilePhoto = props.listObject.getPhoto();
    }
    var imgSrc;
    if (profilePhoto !== undefined) {
        imgSrc = "data:image/png;base64," + profilePhoto;
    } else
        imgSrc = defaultContactImage;
    var name;
    if (props.listObject.getName() === null) {
        if (props.listObject.getSameNumCount() === 0) {
            name = props.listObject.getNumber();
        } else {
            name = props.listObject.getNumber() + " (" + props.listObject.getSameNumCount() + ")";
        }
    } else {
        if (props.listObject.getSameNumCount() === 0) {
            name = props.listObject.getName();
        } else {
            name = props.listObject.getName() + " (" + props.listObject.getSameNumCount() + ")";
        }
    }
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr 2fr', width: (props.listWidth - 2 * padding) + 'px', padding: padding + 'px' }}>
            <div>
                <img src={callIcon} height={20} width={20} />
            </div>
            <div dir='rtl' style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr', rowGap: '0px' }}>
                <span dir='rtl' style={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'right' }}>{name}</span>
                <span dir='rtl' style={{ fontSize: '10px', textAlign: 'right' }}>{props.listObject.getNumber()}</span>
                <span dir='rtl' style={{ fontSize: '10px', textAlign: 'right' }}>{new Date(props.listObject.getDate()).toLocaleString('en-GB')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={imgSrc} height={40} width={40} />
            </div>
        </div>
    );
}