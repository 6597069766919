export class User {
    constructor(userId, userName, isContact, contactId, lastCallDate, lastCallDuration, lastCallCallType, callCounter, totalCallDuration, lastActionDate, lastActionType, lastActionId, actionCounter, flagDelete, phoneNumber, mainImage) {
        this.userId = userId;
        this.userName = userName;
        this.isContact = isContact;
        this.contactId = contactId;
        this.lastCallDate = lastCallDate;
        this.lastCallDuration = lastCallDuration;
        this.lastCallCallType = lastCallCallType;
        this.callCounter = callCounter;
        this.totalCallDuration = totalCallDuration;
        this.lastActionDate = lastActionDate;
        this.lastActionType = lastActionType;
        this.lastActionId = lastActionId;
        this.actionCounter = actionCounter;
        this.flagDelete = flagDelete;
        this.phoneNumber = phoneNumber;
        this.mainImage = mainImage;
    }

    getUserId() {
        return this.userId;
    }
    setUserId(userId) {
        this.userId = userId;
    }
    getUserName() {
        return this.userName;
    }
    setUserName(userName) {
        this.userName = userName;
    }
    getIsContact() {
        return this.isContact;
    }
    getContactId() {
        return this.contactId;
    }
    getLastCallDate() {
        return this.lastCallDate;
    }
    getLastCallDuration() {
        return this.lastCallDuration;
    }
    getLastCallCallType() {
        return this.lastCallCallType;
    }
    getCallCounter() {
        return this.callCounter;
    }
    getTotalCallDuration() {
        return this.totalCallDuration;
    }
    getLastActionDate() {
        return this.lastActionDate;
    }
    setLastActionDate(lastActionDate) {
        this.lastActionDate = lastActionDate;
    }
    getLastActionType() {
        return this.lastActionType;
    }
    getLastActionId() {
        return this.lastActionId;
    }
    getActionCounter() {
        return this.actionCounter;
    }
    getFlagDelete() {
        return this.flagDelete;
    }
    getPhoneNumber() {
        return this.phoneNumber;
    }
    getMainImage() {
        return this.mainImage;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}