import defaultContactImage from '../assets/ic_account.png';
export default function ContactListItem(props) {
    var padding = 10;
    var profilePhoto = props.listObject.getPhoto();
    var imgSrc;
    if (profilePhoto === null)
        imgSrc = defaultContactImage;
    else
        imgSrc = profilePhoto;
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr', width: (props.listWidth - 2 * padding) + 'px', padding: padding + 'px' }}>
            <div dir='rtl' style={{ display: 'grid', gridTemplateRows: '1fr 1fr', rowGap: '0px' }}>
                <span dir='rtl' style={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'right' }}>{props.listObject.getName()}</span>
                <span dir='rtl' style={{ fontSize: '10px', textAlign: 'right' }}>{props.listObject.getNumber()}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={imgSrc} height={40} width={40} />
            </div>
        </div>
    );
}