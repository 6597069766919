import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material';
import Popup from 'reactjs-popup';
import { PopupContent } from './PopupContent';
export default function ChooseTimePopupContent(props) {
    return (
        <div><TextField id='reminderName' placeholder={"שם התזכורת"} multiline></TextField><br /><LocalizationProvider dateAdapter={AdapterDayjs}><span dir='rtl' style={{ display: 'block', width: '100%', textAlign: 'right' }}>תאריך ושעה:</span><MobileDateTimePicker ampm={false} value={props.date} onChange={props.setDate} referenceDate={dayjs()} /></LocalizationProvider></div>
    );
}