import { useNavigate } from "react-router-dom";
import smartCRMILogo from '../assets/smartcrm-logo.png';
import { GoogleButton } from "react-google-button";
import OvalLayout from "../components/OvalLayout";
import { TextField } from "@mui/material";
import { googleSignIn, emailAndPasswordSignIn, resetPassword, getAllLabels, getAllUserAndLabels, getAllUsers, getAllCallLog, getAllNotifications, getAllWhatsappSendLaters, getAllNotes, getAllImages, getAllAudioNotes, getAllAudioFiles, getAllTemplateMessages, isVipUser, waitForUserStateToChange, getCurrentUser } from "../cloud/CloudUtils";
import { useEffect, useRef, useState } from "react";
import { CallLogInfo } from "../cloud/CallLogInfo";
import Loader from "../components/Loader";
import { onAppCreation } from "../cloud/IdGeneration";
function HomePage() {
    let navigate = useNavigate();
    let [token, setToken] = useState(undefined);
    var [loading, setLoading] = useState(false);
    var first = useRef(true);

    function getAllContacts(lastPageToken, currentContacts) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            if (lastPageToken === undefined)
                xhr.open('GET', 'https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,photos&pageSize=1000');
            else
                xhr.open('GET', 'https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,photos&pageSize=1000&pageToken=' + lastPageToken);
            xhr.onreadystatechange = function (e) {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    var response = JSON.parse(xhr.response);
                    var contacts = response.connections;
                    if (contacts !== undefined)
                        currentContacts.push(...contacts);
                    if (response.nextPageToken !== undefined)
                        getAllContacts(response.nextPageToken, currentContacts).then((allContacts) => {
                            resolve(allContacts);
                        });
                    else
                        resolve(currentContacts);
                }
            };
            xhr.setRequestHeader("Authorization", "Bearer " + token);
            xhr.send(null);
        });
    }

    function urlToData(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    useEffect(() => {
        waitForUserStateToChange().then((user) => {
            if (user) {
                if (first.current) {
                    first.current = false;
                    if (JSON.parse(localStorage.getItem('firstTokenExpiration')) !== null && JSON.parse(localStorage.getItem('firstTokenExpiration')) > new Date().getTime())
                        setToken(JSON.parse(localStorage.getItem('firstToken')));
                }
            }
        });
    }, []);

    useEffect(() => {
        enterApp();
    }, token);

    function enterApp() {
        if (token !== undefined) {
            setLoading(true);
            isVipUser().then((isVip) => {
                if (isVip) {
                    getAllContacts(undefined, []).then((contacts) => {
                        for (var i = 0; i < contacts.length;) {
                            if (contacts[i].names === undefined || contacts[i].phoneNumbers === undefined || contacts[i].photos === undefined) {
                                contacts.splice(i, 1);
                            } else
                                i++;
                        }
                        var callLogContacts = [];
                        var numOfContacts = 0;
                        var finished = new Promise((resolve, reject) => {
                            if (contacts.length === 0) {
                                resolve();
                                return;
                            }
                            contacts.forEach(contact => {
                                urlToData(contact.photos[0].url, (data) => {
                                    callLogContacts.push(new CallLogInfo(null, null, null, null, contact.names[0].displayName, data, contact.phoneNumbers[0].value, null, null, null, null, true));
                                    numOfContacts++;
                                    if (numOfContacts === contacts.length)
                                        resolve();
                                })
                            });
                        });
                        getAllLabels().then((labels) => {
                            localStorage.setItem('labels', JSON.stringify(labels));
                            getAllUserAndLabels().then((userAndLabels) => {
                                localStorage.setItem('userAndLabels', JSON.stringify(userAndLabels));
                                getAllUsers().then((users) => {
                                    localStorage.setItem('users', JSON.stringify(users));
                                    getAllCallLog().then((callLogs) => {
                                        localStorage.setItem('calls', JSON.stringify(callLogs));
                                        getAllNotifications().then((notifications) => {
                                            localStorage.setItem('notifications', JSON.stringify(notifications));
                                            getAllWhatsappSendLaters().then((whatsappSendLaters) => {
                                                localStorage.setItem('whatsapps', JSON.stringify(whatsappSendLaters));
                                                getAllNotes().then((notes) => {
                                                    localStorage.setItem('notes', JSON.stringify(notes));
                                                    getAllImages().then((images) => {
                                                        localStorage.setItem('images', JSON.stringify(images));
                                                        getAllAudioNotes().then((audioNotes) => {
                                                            localStorage.setItem('audios', JSON.stringify(audioNotes));
                                                            getAllAudioFiles().then((audioFiles) => {
                                                                localStorage.setItem('audioFiles', JSON.stringify(audioFiles));
                                                                getAllTemplateMessages().then((templateMessages) => {
                                                                    localStorage.setItem('templateMessages', JSON.stringify(templateMessages));
                                                                    finished.then(() => {
                                                                        localStorage.setItem('contacts', JSON.stringify(callLogContacts));
                                                                        if (localStorage.getItem('firstToken') === null || JSON.parse(localStorage.getItem('firstTokenExpiration')) === null || JSON.parse(localStorage.getItem('firstTokenExpiration')) < new Date().getTime()) {
                                                                            localStorage.setItem('firstToken', JSON.stringify(token));
                                                                            localStorage.setItem('firstTokenExpiration', JSON.stringify(new Date().getTime() + 3500000));
                                                                        }
                                                                        navigate("/call-log");
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                } else {
                    setLoading(false);
                    alert('אינך משתמש vip');
                }
            });
        } else if (first.current) {
            onAppCreation();
        }
    }

    if (loading)
        return (<Loader />);
    else {
        return (
            <div style={{ display: 'grid', justifyContent: 'center', height: '100svh', gridTemplateAreas: `'title logo' 'google-text google-text' 'google-button google-button' 'regular-text regular-text' 'regular-email regular-email' 'regular-password regular-password' 'regular-button regular-button' 'forgot-password forgot-password'`, rowGap: '20px', columnGap: '20px', alignItems: 'center', alignContent: 'center' }}>
                <span style={{ gridArea: 'title', fontSize: '40px', fontWeight: 'bold' }}>Smart CRM</span><img src={smartCRMILogo} alt="smartcrm logo" height={100} width={100} />
                <span style={{ gridArea: 'google-text', textAlign: 'center', fontWeight: 'bold', color: 'gray' }}>כניסה באמצעות גוגל</span>
                <div style={{ gridArea: 'google-button', display: 'flex', justifyContent: 'center' }}><GoogleButton label={'כניסה'} type={'light'} onClick={() => {
                    googleSignIn().then(token => { setToken(token); }).catch((err) => {
                        if (err === 'does not exist') alert('אינך רשום לאפליקציה\nזיהינו שהמייל הזה עוד לא רשום באפליקציה.\nכדי לאתר לאתר, יש להירשם קודם לאפליקציה.\nהירשמו באפליקציה, רכשו מנוי VIP, ולאחר מכן חזרו לאתר ובצעו התחברות.'); else if (err.code !== 'auth/popup-closed-by-user' && err.code !== 'auth/cancelled-popup-request') alert('ההתחברות נכשלה')
                    });
                }} /></div>
                <span style={{ gridArea: 'regular-text', textAlign: 'center', fontWeight: 'bold', color: 'gray' }}>או באמצעות מייל וסיסמא</span>
                <TextField dir="rtl" variant="standard" id="email" style={{ gridArea: 'regular-email' }} placeholder="מייל" />
                <TextField dir="rtl" variant="standard" id="password" type="password" style={{ gridArea: 'regular-password' }} placeholder="סיסמא" />
                <div style={{ gridArea: 'regular-button', display: 'flex', justifyContent: 'center' }}><OvalLayout contentType={"text"} width={"widest"} backgroundColor={"rgb(209, 147, 13)"} textColor={"white"} text={"כניסה"} onClick={() => { emailAndPasswordSignIn(document.getElementById("email").value, document.getElementById("password").value).then(() => { enterApp(); }).catch(() => { alert("המייל או הסיסמא אינם תקינים"); }); }} /></div>
                <button type="button" onClick={() => { resetPassword(document.getElementById("email").value).then(() => { alert("המייל נשלח בהצלחה"); }).catch(() => { alert("המייל אינו קיים") }); }} dir="rtl" style={{ gridArea: 'forgot-password', backgroundColor: 'transparent', border: 'none', fontSize: '16px' }}>שכחת סיסמא?</button>
            </div>
        );
    }
}
export default HomePage;