
import whatsappIcon from '../assets/ic_whatsapp.png';
import phoneIcon from '../assets/ic_call.png';
import { UserPropertyItem } from './UserPropertyItem';
import { addNote, addUser, addUserAndLabel, deleteUserAndLabel, createLabel, createCloudObjectArray, createNote, addImage, createImage, addNotification, createNotification, createWhatsappSendLater, addWhatsappSendLater, addAudioNote, createAudioNote, createAudioFile, addIsraelCountryCode, deleteAudioNote, deleteWhatsappSendLater, deleteNotification, deleteImage, deleteNote, deleteLabel } from "../cloud/CloudUtils";
import { Note } from "../cloud/Note";
import { generateGlobalUniqueId } from "../cloud/IdGeneration";
import { LabelsList } from './LabelsList';
import { useEffect, useState } from 'react';
import { UserAndLabel } from '../cloud/UserAndLabel';
import { createUserAndLabel } from '../cloud/CloudUtils';
import OvalLayout from './OvalLayout';
import { TextField } from '@mui/material';
import { Image as Img } from '../cloud/Image';
import { Notification } from '../cloud/Notification';
import dayjs from 'dayjs';
import { WhatsappSendLater } from '../cloud/WhatsappSendLater';
import AudioRecorder from './audio_recorder/components/AudioRecordingComponent.tsx';
import { AudioNote } from '../cloud/AudioNote';
import { Constants } from '../cloud/Constants';
import DeletePopup from './DeletePopup';
import Popup from 'reactjs-popup';
import { PopupContent } from './PopupContent';
import { useNavigate } from 'react-router-dom';
import WhatsappReminderPopupContent from './WhatsappReminderPopupContent';
import ChooseTimePopupContent from './ChooseTimePopupContent';
export default function UserData(props) {
    var [activatedLabels, setActivatedLabels] = useState([]);
    var [deactivatedLabels, setDeactivatedLabels] = useState([]);
    var [name, setName] = useState(null);
    var [date, setDate] = useState(dayjs());
    var [nameText, setNameText] = useState("");
    var navigate = useNavigate();
    var contentWidth = 340;
    if (name !== props.user.getUserName()) {
        changeName(props.user.getUserName());
    }
    function onNameEnterClicked(event) {
        let text = event.target.value;
        dealWithUser().then(async () => {
            await dealWithUser();
            if (text === "")
                text = null;
            changeName(text);
            let listObjects = [...props.listObjects];
            for (let i = 0; i < listObjects.length; i++) {
                if (listObjects[i].getNumber() === props.user.getPhoneNumber()) {
                    listObjects[i].setName(text);
                    break;
                }
            }
            await addUser(props.user);
            props.onListObjectsChangedLocally(listObjects);
        });
    }

    function changeName(newName) {
        props.user.setUserName(newName);
        setName(newName);
        changeNameText(newName);
    }

    function changeNameText(newNameText) {
        if (newNameText !== null)
            setNameText(newNameText);
        else
            setNameText("");
    }

    async function dealWithUser() {
        props.user.setLastActionDate(new Date().getTime());
        if (props.user.getUserId() === null) {
            props.user.setUserId(generateGlobalUniqueId());
        }
        await addUser(props.user);
    }

    async function dealWithUserAndLabel() {
        let userAndLabelObjects = createCloudObjectArray("userAndLabels", createUserAndLabel);
        for (let i = 0; i < activatedLabels.length; i++) {
            let userAndLabel = new UserAndLabel(props.user.getUserId(), activatedLabels[i].getLabelId(), generateGlobalUniqueId());
            userAndLabelObjects.push(userAndLabel);
            await addUserAndLabel(userAndLabel);
        }
        for (let i = 0; i < deactivatedLabels.length; i++) {
            var userAndLabel;
            for (let j = 0; j < userAndLabelObjects.length; j++) {
                if (userAndLabelObjects[j].getLabelId() === deactivatedLabels[i].getLabelId() && userAndLabelObjects[j].getUserId() === props.user.getUserId()) {
                    userAndLabel = userAndLabelObjects[j];
                    break;
                }
            }
            await deleteUserAndLabel(userAndLabel);
        }
        setActivatedLabels([]);
        setDeactivatedLabels([]);
    }

    async function dealWithNote() {
        let text = document.getElementById('noteInput').value;
        await addNote(new Note(generateGlobalUniqueId(), props.user.getUserId(), new Date().getTime(), text));
    }

    async function dealWithImage() {
        let file = document.getElementById('imageInput')['files'][0];
        let reader = new FileReader();
        reader.onload = async function (e) {
            const img = new Image();
            const MAX_WIDTH = 500;
            const MAX_HEIGHT = 500;
            console.log(e.target.result);
            img.onload = function () {
                const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                var data_URL = canvas.toDataURL('image/png');   /* Returns correct data URL */
                console.log(data_URL);
                canvas.toBlob(function (blob) {
                    blob.arrayBuffer().then(async function (bytes) {
                        await addImage(new Img(generateGlobalUniqueId(), Array.from(new Int8Array(bytes)), props.user.getUserId(), new Date().getTime(), null));
                    });
                });
            };
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);
    }

    function calculateSize(img, maxWidth, maxHeight) {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }

    async function dealWithNotification() {
        var notificationText = document.getElementById('reminderName').value;
        var notificationDate = date.toDate();
        var contactIdentifier = props.user.getUserName();
        if (contactIdentifier === null)
            contactIdentifier = props.user.getPhoneNumber();
        var title = "תזכורת לגבי איש קשר: " + contactIdentifier;
        await addNotification(new Notification(generateGlobalUniqueId(), props.user.getUserId(), notificationText, title, notificationDate.getTime()));
    }

    async function dealWithWhatsapp() {
        var whatsappText = document.getElementById('reminderName').value;
        var whatsappDate = date.toDate();
        await addWhatsappSendLater(new WhatsappSendLater(generateGlobalUniqueId(), props.user.getUserId(), props.user, whatsappText, props.user.getPhoneNumber(), whatsappDate.getTime(), null, null, null));
    }

    async function onRecordingStopped(blob, close) {
        await dealWithUser();
        var time = new Date().getTime();
        var location = Constants.AUDIOS_DIR + "/" + time + ".webm";
        await addAudioNote(new AudioNote(generateGlobalUniqueId(), props.user.getUserId(), time, location), blob);
        alert("ההקלטה נשמרה");
        close();
    }

    function onLabelCheckboxChanged(label, isChecked) {
        if (isChecked) {
            if (deactivatedLabels.includes(label)) {
                let newDeactivatedLabels = [...deactivatedLabels];
                newDeactivatedLabels.splice(deactivatedLabels.indexOf(label), 1);
                setDeactivatedLabels(newDeactivatedLabels);
            } else {
                let newActivatedLabels = [...activatedLabels];
                newActivatedLabels.push(label);
                setActivatedLabels(newActivatedLabels);
            }
        } else {
            if (activatedLabels.includes(label)) {
                let newActivatedLabels = [...activatedLabels];
                newActivatedLabels.splice(activatedLabels.indexOf(label), 1);
                setActivatedLabels(newActivatedLabels);
            } else {
                let newDeactivatedLabels = [...deactivatedLabels];
                newDeactivatedLabels.push(label);
                setDeactivatedLabels(newDeactivatedLabels);
            }
        }
    }

    function getMyLabelGraphicItems() {
        let labelObjects = createCloudObjectArray("labels", createLabel);
        let userAndLabelObjects = createCloudObjectArray("userAndLabels", createUserAndLabel);
        let myUserAndLabels = [];
        let myLabels = [];
        for (let i = 0; i < userAndLabelObjects.length; i++) {
            if (userAndLabelObjects[i].getUserId() === props.user.getUserId()) {
                myUserAndLabels.push(userAndLabelObjects[i]);
                for (let j = 0; j < labelObjects.length; j++) {
                    if (labelObjects[j].getLabelId() === userAndLabelObjects[i].getLabelId()) {
                        myLabels.push(labelObjects[j]);
                        break;
                    }
                }
            }
        }
        let labelGraphicItems = [];
        for (let i = 0; i < myLabels.length; i++) {
            labelGraphicItems.push(<DeletePopup itemContent={<OvalLayout backgroundColor={"#" + myLabels[i].getColor()} contentType="text" text={myLabels[i].getText()} height='short' />} deleteFunction={async () => { deleteUserAndLabel(myUserAndLabels[i]); }} />);
        }
        return labelGraphicItems;
    }

    function getMyNoteGraphicItems() {
        let noteObjects = createCloudObjectArray("notes", createNote);
        let myNotes = [];
        for (let i = 0; i < noteObjects.length; i++) {
            if (noteObjects[i].getUserCreatorId() === props.user.getUserId()) {
                myNotes.push(noteObjects[i]);
            }
        }
        let noteGraphicItems = [];
        for (let i = 0; i < myNotes.length; i++) {
            noteGraphicItems.push(<DeletePopup itemContent={<div><span dir='rtl'>{myNotes[i].getText()}</span><br /> <span>{new Date(myNotes[i].getTimestamp()).toLocaleString('en-GB')}</span></div>} deleteFunction={async () => { deleteNote(myNotes[i]); }} />);
        }
        return noteGraphicItems;
    }

    function getMyImageGraphicItems() {
        let imageObjects = createCloudObjectArray("images", createImage);
        let myImages = [];
        for (let i = 0; i < imageObjects.length; i++) {
            if (imageObjects[i].getUserCreatorId() === props.user.getUserId()) {
                myImages.push(imageObjects[i]);
            }
        }
        let imageGraphicItems = [];
        for (let i = 0; i < myImages.length; i++) {
            var data = new Uint8Array(myImages[i].getImageCompressedData());
            let binaryString = "";
            for (let j = 0; j < data.length; j++) {
                binaryString += String.fromCharCode(data[j]);
            }
            let base64 = btoa(binaryString);
            imageGraphicItems.push(<DeletePopup itemContent={<div><Popup trigger={<img src={"data:image/png;base64," + base64} height={100} width={100} />} position={"center center"} modal nested>{close => (<PopupContent content={<img src={"data:image/png;base64," + base64} width={400} />} onClose={close} width="420px" />)}</Popup></div>} deleteFunction={async () => { deleteImage(myImages[i]); }} />);
        }
        return imageGraphicItems;
    }

    function getMyNotificationGraphicItems() {
        let notificationObjects = createCloudObjectArray("notifications", createNotification);
        let myNotifications = [];
        for (let i = 0; i < notificationObjects.length; i++) {
            if (notificationObjects[i].getUserCreatorId() === props.user.getUserId()) {
                myNotifications.push(notificationObjects[i]);
            }
        }
        let notificationGraphicItems = [];
        for (let i = 0; i < myNotifications.length; i++) {
            notificationGraphicItems.push(<DeletePopup itemContent={<div><span dir='rtl'>{myNotifications[i].getText()}</span><br /><span>{new Date(myNotifications[i].getTimestamp()).toLocaleString('en-GB')}</span></div>} deleteFunction={async () => { deleteNotification(myNotifications[i]); }} />);
        }
        return notificationGraphicItems;
    }

    function getMyWhatsappGraphicItems() {
        let whatsappObjects = createCloudObjectArray("whatsapps", createWhatsappSendLater);
        let myWhatsapps = [];
        for (let i = 0; i < whatsappObjects.length; i++) {
            if (whatsappObjects[i].getUserCreatorId() === props.user.getUserId()) {
                myWhatsapps.push(whatsappObjects[i]);
            }
        }
        let whatsappGraphicItems = [];
        for (let i = 0; i < myWhatsapps.length; i++) {
            whatsappGraphicItems.push(<DeletePopup itemContent={<div><span dir='rtl'>{myWhatsapps[i].getText()}</span><br /><span>{new Date(myWhatsapps[i].getTimestamp()).toLocaleString('en-GB')}</span></div>} deleteFunction={async () => { deleteWhatsappSendLater(myWhatsapps[i]); }} />);
        }
        return whatsappGraphicItems;
    }

    function getMyAudioGraphicItems() {
        let audioObjects = createCloudObjectArray("audios", createAudioNote);
        let myAudios = [];
        for (let i = 0; i < audioObjects.length; i++) {
            if (audioObjects[i].getUserCreatorId() === props.user.getUserId()) {
                myAudios.push(audioObjects[i]);
            }
        }
        let audioFiles = createCloudObjectArray("audioFiles", createAudioFile);
        let audioGraphicItems = [];
        for (let i = 0; i < myAudios.length; i++) {
            let audioFile;
            for (let j = 0; j < audioFiles.length; j++) {
                if (audioFiles[j].getLocation() === myAudios[i].getFileLocation().split("/").pop()) {
                    audioFile = audioFiles[j];
                    break;
                }
            }
            let base64 = audioFile.getBlobText();
            let binaryString = atob(base64);
            let bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            let blobType = audioFile.getBlobType();
            let blob = new Blob([bytes.buffer], { type: blobType });
            let url = URL.createObjectURL(blob);
            audioGraphicItems.push(<DeletePopup itemContent={<div><audio src={url} controls></audio></div>} deleteFunction={async () => { deleteAudioNote(myAudios[i]); }} />);
        }
        return audioGraphicItems;
    }
    return (
        <div dir='ltr' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', width: contentWidth + 'px' }}>
            <input id='nameText' value={nameText} onChange={() => { changeNameText(document.getElementById('nameText').value) }} type='text' style={{ backgroundColor: 'orange', width: '100%', textAlign: 'center', fontSize: '14px', height: '18px', padding: '5px', border: 'none' }} placeholder='לחצו והוסיפו את שם הלקוח/ה' onKeyUp={(e) => { if (e.key === 'Enter') onNameEnterClicked(e); }}></input>
            <div style={{ border: '3px solid gray', display: 'grid', gridTemplateColumns: '1fr 1fr 4fr', columnGap: '10px', width: contentWidth + 'px', padding: '5px', boxSizing: 'border-box' }}>
                <Popup trigger={<img src={whatsappIcon} height={20} width={20} />} position={"center center"} modal nested>{close => (<PopupContent height={200} onClose={close} content={<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}><OvalLayout contentType={"text"} backgroundColor={"orange"} text={"הודעה חדשה"} width={"wider"} borderRadius={6} onClick={() => { window.open('https://wa.me/' + addIsraelCountryCode(props.user.getPhoneNumber()) + "?text=", '_blank').focus(); }} /><OvalLayout contentType={"text"} backgroundColor={"orange"} text={"הודעה מוכנה מתבנית"} width={"wider"} borderRadius={6} onClick={() => { localStorage.setItem('whatsappTemplateUser', JSON.stringify(props.user)); localStorage.setItem('isWhatsappReminder', JSON.stringify(false)); navigate("/whatsappTemplates"); }} /><Popup trigger={<OvalLayout contentType={"text"} backgroundColor={"orange"} text={"תזכורת למועד מאוחר יותר"} width={"wider"} borderRadius={6} />} position={"center center"} modal nested>{close => (<PopupContent height={200} onClose={close} content={<WhatsappReminderPopupContent onSave={async () => { await dealWithUser(); await dealWithWhatsapp(); }} user={props.user} close={close} date={date} setDate={setDate} />} />)}</Popup></div>} />)}</Popup>
                <img src={phoneIcon} height={20} width={20} onClick={() => { window.location.href = 'tel:' + props.user.getPhoneNumber() }} />
                <span dir='ltr' style={{ fontWeight: 'bold', textAlign: 'right' }}>{props.user.getPhoneNumber()}</span>
            </div>
            <UserPropertyItem title={"תוויות"} infoText={"הוסיפו תוויות שיעזרו לכם לנהל בקלות את הלידים והלקוחות שלכם.\n\nבלחיצה על האייקון של הסינון בתפריט העליון, תוכלו לראות את כל התוויות שלכם,\nבלחיצה על כל תווית, תוכלו לראות את כל הרשומות ששייכתם לתווית הזאת.\n\nכך תוכלו לעקוב בצורה נוחה וקלה אחרי הלידים והלקוחות שלכם.\n\nכדי לערוך את שם התווית ולהוסיף תוויות חדשות, לחצו על \"ניהול תווית\" בתפריט באפליקציה."} addPopupContent={() => { return <LabelsList user={props.user} onLabelChange={onLabelCheckboxChanged} /> }} onSave={async () => { await dealWithUser(); await dealWithUserAndLabel(); }} contentWidth={contentWidth} content={getMyLabelGraphicItems()} />
            <UserPropertyItem title={"תזכורות"} infoText={"קבעתם לדבר עם הלקוח במועד מאוחר יותר?\nהוסיפו לעצמכם תזכורת.\nכאשר התאריך והשעה שהגדרתם יגיעו, אתם תקבלו נוטיפיקציה לטלפון שלכם,\nבלחיצה על הנוטיפיקציה אתם תגיעו למסך רשומה הזה ותוכלו להתקשר או לשלוח וואטסאפ ללקוח בקלות.\n\nכדי למחוק תזכורת, לחצו עליה לחיצה ארוכה ואז לחצו על האייקון של פח האשפה בחלק העליון במסך."} addPopupContent={() => { return <ChooseTimePopupContent date={date} setDate={setDate} /> }} onSave={async () => { await dealWithUser(); await dealWithNotification(); }} contentWidth={contentWidth} content={getMyNotificationGraphicItems()} />
            <UserPropertyItem title={"הערות"} infoText={"ההערות שתוסיפו יופיעו לכם על המסך כאשר הלקוח יתקשר אליכם, או שאתם תתקשרו אליו.\n\nכך תוכלו לדעת על מה דיברתם עם הלקוח.\n\nכדי למחוק הערה, לחצו עליה לחיצה ארוכה ואז לחצו על האייקון של פח האשפה בחלק העליון במסך."} addPopupContent={() => { return <div><TextField id='noteInput' placeholder={"תכתבו כאן"} multiline></TextField></div> }} contentWidth={contentWidth} onSave={async () => { await dealWithUser(); await dealWithNote(); }} content={getMyNoteGraphicItems()} />
            <UserPropertyItem title={"תמונות"} infoText={"התמונות שתוסיפו יופיעו לכם על המסך כאשר הלקוח יתקשר אליכם, או שאתם תתקשרו אליו.\n\nהוסיפו לדוגמה צילום מסך של התכתבות שלכם בוואטסאפ, כך תוכלו לראות את התמונה לפני שעניתם לשיחה ולדעת על מה דיברתם עם הלקוח.\n\nכדי למחוק תמונה, לחצו על התמונה, בטלו את ה-וי בתחתית המסך ולחצו על כפתור סיום למעלה."} addPopupContent={() => { return <div><br /><input id='imageInput' type="file" accept='image/*' /></div> }} onSave={async () => { await dealWithUser(); await dealWithImage(); }} contentWidth={contentWidth} content={getMyImageGraphicItems()} />
            <UserPropertyItem title={"הערות קוליות"} infoText={"הוסיפו הערות קוליות וסכמו לעצמכם את השיחה עם הלקוח, כך תוכלו להיכנס בכל שלב שתרצו לרשומת השיחה ולהאזין להודעות הקוליות שהוספתם.\n\nכדי למחוק הערה, לחצו עליה לחיצה ארוכה ואז לחצו על האייקון של פח האשפה בחלק העליון במסך."} addPopupContent={(close) => { return <div><AudioRecorder onRecordingComplete={(blob) => onRecordingStopped(blob, close)} audioTrackConstraints={{ noiseSuppression: true, echoCancellation: true }} downloadOnSavePress={false} downloadFileExtension="webm" /></div> }} contentWidth={contentWidth} content={getMyAudioGraphicItems()} />
            <UserPropertyItem title={"תזכורות להודעות וואטסאפ"} infoText={"צריכים לשלוח וואטסאפ במועד מאוחר יותר?\nרשמו את ההודעה ובחרו את התאריך והשעה שבה היא תשלח.\nברגע שיגיע הזמן שהגדרתם, תקבלו נוטיפיקציה.\nבלחיצה על הנוטיפיקציה, תעברו לשיחה בווטאסאפ עם המספר שתיזמנתם לו את ההודעה, וכל הטקסט שרשמתם כבר יופיע בוואטסאפ.\nכל שעליכם לעשות זה ללחוץ על שלח בתוך הוואטסאפ וההודעה שרשמתם תישלח.\n\nכדי למחוק תזכורת להודעת וואטסאפ, לחצו עליה לחיצה ארוכה ואז לחצו על האייקון של פח האשפה בחלק העליון במסך."} addPopupHeight={200} addPopupContent={() => { return <WhatsappReminderPopupContent onSave={async () => { await dealWithUser(); await dealWithWhatsapp(); }} user={props.user} date={date} setDate={setDate} /> }} contentWidth={contentWidth} content={getMyWhatsappGraphicItems()} />
        </div>
    );
}