import Page from "../components/Page";
import React, { useState } from "react";
import UserListItem from "../components/UserListItem";
import { createCloudObjectArray, createUser, deleteUser } from "../cloud/CloudUtils";
import deleteIcon from '../assets/trash-can.png';
function TrackingPage() {
    var listWidth = 340;

    function createListItem(listObject) {
        return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: listWidth }}><UserListItem listObject={listObject} listWidth={listWidth - 40}></UserListItem><img width={30} height={30} src={deleteIcon} onClick={(e) => { e.preventDefault(); deleteUser(listObject); }} /></div>);
    }
    function onListItemClick(listObject) {
        return listObject;
    }

    function filter(listObject, text) {
        if (listObject.getUserName() !== null && listObject.getPhoneNumber() !== null)
            return listObject.getUserName().startsWith(text) || listObject.getPhoneNumber().startsWith(text);
        else if (listObject.getUserName() !== null)
            return listObject.getUserName().startsWith(text)
        else if (listObject.getPhoneNumber() !== null)
            return listObject.getPhoneNumber().startsWith(text);
        return false;
    }

    function getAllUsers() {
        return new Promise((resolve, reject) => {
            let users = createCloudObjectArray("users", createUser);
            resolve(users);
        });
    }

    return (
        <Page listFunction={getAllUsers} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listWidth={listWidth} title={"במעקב - כל הרשומות שהוספתם להן מידע"} listItemHeight={75} index={2} />
    );

}
export default TrackingPage;