export class AudioNote {
    constructor(audioNoteId, userCreatorId, timestamp, fileLocation) {
        this.audioNoteId = audioNoteId;
        this.userCreatorId = userCreatorId;
        this.timestamp = timestamp;
        this.fileLocation = fileLocation;
    }
    getAudioNoteId() {
        return this.audioNoteId;
    }
    getUserCreatorId() {
        return this.userCreatorId;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getFileLocation() {
        return this.fileLocation;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}