import { initializeApp } from "firebase/app";
import { Label } from "./Label";
import { Image } from "./Image";
import { Note } from "./Note";
import { Notification } from "./Notification";
import { User } from "./User";
import { AudioNote } from "./AudioNote";
import { UserAndLabel } from "./UserAndLabel";
import { WhatsappSendLater } from "./WhatsappSendLater";
import { getStorage, ref, uploadString, getBytes, listAll, deleteObject, uploadBytes, getBlob } from "firebase/storage";
import { getDatabase, ref as ref2, set, onValue, get } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { TemplateMessage } from "./TemplateMessage";
import { CallLogInfo } from "./CallLogInfo";
import { AudioFile } from "./AudioFile";
import { SAMLAuthProvider } from "firebase/auth";


const firebaseApp = {
    apiKey: "AIzaSyASNyfrKTsXcrYuzzs7HDFd8_4AWvbqmyI",
    authDomain: "smartrecords-cdc2e.firebaseapp.com",
    databaseURL: "https://smartrecords-cdc2e-default-rtdb.firebaseio.com",
    projectId: "smartrecords-cdc2e",
    storageBucket: "smartrecords-cdc2e.appspot.com",
    messagingSenderId: "709681045945",
    appId: "1:709681045945:web:a65142ac26bf949a9c5668",
    measurementId: "G-3SNRWH1JFR"
};

const app = initializeApp(firebaseApp);
const db = getDatabase(app);
const storage = getStorage(app, "gs://smartrecords-cdc2e");
var refreshFunction;

//------------------------------------------------------------
export function listenForChanges() {
    console.log("listen");
    var first = true;
    var myRef;
    onAuthStateChanged(getAuth(), (user) => {
        console.log("user: " + user);
        if (user) {
            myRef = ref2(db, "users/" + user.uid + "/lastAction");
            onValue(myRef, async (snapshot) => {
                if (!first) {
                    //add to the appropriate list.
                    //refresh user data if needed.
                    let value = snapshot.val();
                    console.log("value: " + value);
                    let fields = value.split(" ");
                    let fileType = fields[2];
                    let fileId = fields[1];
                    let changeType = fields[3];
                    switch (changeType) {
                        case "Add":
                            switch (fileType) {
                                case "Image":
                                    await getAndAddObjectToLocalStorageArray("images", fileId, createImage, (image) => { return image.getImageId() });
                                    break;
                                case "Note":
                                    await getAndAddObjectToLocalStorageArray("notes", fileId, createNote, (note) => { return note.getNoteId() });
                                    break;
                                case "User":
                                    await getAndAddObjectToLocalStorageArray("users", fileId, createUser, (user) => { return user.getUserId() });
                                    break;
                                case "Label":
                                    await getAndAddObjectToLocalStorageArray("labels", fileId, createLabel, (label) => { return label.getLabelId() });
                                    break;
                                case "Audio":
                                    var audio = await getAndAddObjectToLocalStorageArray("audios", fileId, createAudioNote, (audioNote) => { return audioNote.getAudioNoteId() });
                                    var location = audio.getFileLocation().split("/").pop();
                                    var blob = await getBlob(ref(storage, getCurrentUser().uid + "/audioFiles/" + location));
                                    var audioFilesArray = createCloudObjectArray("audioFiles", createAudioFile);
                                    for (let i = 0; i < audioFilesArray.length; i++) {
                                        if (audioFilesArray[i].getLocation() === location) {
                                            audioFilesArray.splice(i, 1);
                                            break;
                                        }
                                    }
                                    let blobBuffer = await blob.arrayBuffer();
                                    let bytes = new Uint8Array(blobBuffer);
                                    let str = "";
                                    for (let j = 0; j < bytes.byteLength; j++) {
                                        str = str.concat(String.fromCharCode(bytes[j]));
                                    }
                                    var base64 = btoa(str);
                                    audioFilesArray.push(new AudioFile(base64, blob.type, location));
                                    localStorage.setItem("audioFiles", JSON.stringify(audioFilesArray));
                                    break;
                                case "Whatsapp":
                                    await getAndAddObjectToLocalStorageArray("whatsapps", fileId, createWhatsappSendLater, (whatsappSendLater) => { return whatsappSendLater.getWhatsappSendLaterId() });
                                    break;
                                case "Call":
                                    await getAndAddObjectToLocalStorageArray("calls", fileId, createCallLogInfo, (callLogInfo) => { return callLogInfo.getCallLogId() });
                                    break;
                                case "UserAndLabel":
                                    await getAndAddObjectToLocalStorageArray("userAndLabels", fileId, createUserAndLabel, (userAndLabel) => { return userAndLabel.getUserAndLabelId() });
                                    break;
                                case "TemplateMessage":
                                    await getAndAddObjectToLocalStorageArray("templateMessages", fileId, createTemplateMessage, (templateMessage) => { return templateMessage.getTemplateMessageId() });
                                    break;
                                case "Notification":
                                    await getAndAddObjectToLocalStorageArray("notifications", fileId, createNotification, (notification) => { return notification.getNotificationId() });
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case "Delete":
                            switch (fileType) {
                                case "Image":
                                    deleteObjectFromLocalStorage("images", fileId, (image) => { return image.getImageId() }, createImage);
                                    break;
                                case "Note":
                                    deleteObjectFromLocalStorage("notes", fileId, (note) => { return note.getNoteId() }, createNote);
                                    break;
                                case "User":
                                    deleteObjectFromLocalStorage("users", fileId, (user) => { return user.getUserId() }, createUser);
                                    break;
                                case "Label":
                                    deleteObjectFromLocalStorage("labels", fileId, (label) => { return label.getLabelId() }, createLabel);
                                    break;
                                case "Audio":
                                    let audioNote = deleteObjectFromLocalStorage("audios", fileId, (audioNote) => { return audioNote.getAudioNoteId() }, createAudioNote);
                                    deleteObjectFromLocalStorage("audioFiles", audioNote.getFileLocation().split("/").pop(), (audioFile) => { return audioFile.getLocation() }, createAudioFile);
                                    break;
                                case "Whatsapp":
                                    deleteObjectFromLocalStorage("whatsapps", fileId, (whatsappSendLater) => { return whatsappSendLater.getWhatsappSendLaterId() }, createWhatsappSendLater);
                                    break;
                                case "Call":
                                    deleteObjectFromLocalStorage("calls", fileId, (callLogInfo) => { return callLogInfo.getCallLogInfoId() }, createCallLogInfo);
                                    break;
                                case "UserAndLabel":
                                    deleteObjectFromLocalStorage("userAndLabels", fileId, (userAndLabel) => { return userAndLabel.getUserAndLabelId() }, createUserAndLabel);
                                    break;
                                case "TemplateMessage":
                                    deleteObjectFromLocalStorage("templateMessages", fileId, (templateMessage) => { return templateMessage.getTemplateMessageId() }, createTemplateMessage);
                                    break;
                                case "Notification":
                                    deleteObjectFromLocalStorage("notifications", fileId, (notification) => { return notification.getNotificationId() }, createNotification);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                    if (refreshFunction !== undefined)
                        refreshFunction();
                } else
                    first = false;
            });
        } else {
            console.log("off");
            if (myRef !== undefined)
                myRef.off();
        }
    });
}

export function setCurrentRefreshFunction(func) {
    refreshFunction = func;
}

export function logout() {
    getAuth().signOut();
}
function getAndAddObjectToLocalStorageArray(arrayName, objectId, createObjectFunction, getIdFunction) {
    return new Promise((resolve, reject) => {
        getObjectFromFirebaseStorage(arrayName, objectId).then((bytes) => {
            resolve(addObjectToLocalStorageArrayFromBytes(arrayName, bytes, getIdFunction, createObjectFunction));
        });
    })
}
function addObjectToLocalStorageArrayFromBytes(arrayName, bytes, getIdFunction, objectCreationFunction) {
    var object = objectCreationFunction(new TextDecoder().decode(new Uint8Array(bytes)));
    var objectsArray = createCloudObjectArray(arrayName, objectCreationFunction);
    for (let i = 0; i < objectsArray.length; i++) {
        if (getIdFunction(objectsArray[i]) === getIdFunction(object)) {
            objectsArray.splice(i, 1);
            break;
        }
    }
    objectsArray.push(object);
    localStorage.setItem(arrayName, JSON.stringify(objectsArray));
    return object;
}
function deleteObjectFromLocalStorage(arrayName, objectId, getIdFunction, objectCreationFunction) {
    var objectsArray = createCloudObjectArray(arrayName, objectCreationFunction);
    for (let i = 0; i < objectsArray.length; i++) {
        if (getIdFunction(objectsArray[i]) === objectId) {
            let deletedObject = objectsArray[i];
            objectsArray.splice(i, 1);
            localStorage.setItem(arrayName, JSON.stringify(objectsArray));
            return deletedObject;
        }
    }
}
async function getObjectFromFirebaseStorage(arrayName, objectId) {
    return await getBytes(ref(storage, getCurrentUser().uid + "/" + arrayName + "/" + objectId));
}
export function createLabel(json) {
    const label = new Label();
    label.fromJson(json);
    return label;
}
export function createImage(json) {
    const image = new Image();
    image.fromJson(json);
    return image;
}
export function createNote(json) {
    const note = new Note();
    note.fromJson(json);
    return note;
}
export function createNotification(json) {
    const notification = new Notification();
    notification.fromJson(json);
    return notification;
}
export function createUser(json) {
    const user = new User();
    user.fromJson(json);
    return user;
}
export function createAudioNote(json) {
    const audioNote = new AudioNote();
    audioNote.fromJson(json);
    return audioNote;
}
export function createAudioFile(json) {
    const audioFile = new AudioFile();
    audioFile.fromJson(json);
    return audioFile;
}
export function createUserAndLabel(json) {
    const userAndLabel = new UserAndLabel();
    userAndLabel.fromJson(json);
    return userAndLabel;
}
export function createWhatsappSendLater(json) {
    const whatsappSendLater = new WhatsappSendLater();
    whatsappSendLater.fromJson(json);
    return whatsappSendLater;
}
export function createTemplateMessage(json) {
    const templateMessage = new TemplateMessage();
    templateMessage.fromJson(json);
    return templateMessage;
}
export function createCallLogInfo(json) {
    const callLogInfo = new CallLogInfo();
    callLogInfo.fromJson(json);
    return callLogInfo;
}

export function createCloudObjectArray(listName, constructor) {
    var jsonObjects = JSON.parse(localStorage.getItem(listName));
    const cloudObjectArray = [];
    for (let i = 0; i < jsonObjects.length; i++) {
        cloudObjectArray.push(constructor(JSON.stringify(jsonObjects[i])));
    }
    return cloudObjectArray;
}

export async function getObjectByIdAndArrayName(id, arrayName, getIdFunction, createObjectFunction) {
    var objectsArrayJSON = JSON.parse(localStorage.getItem(arrayName));
    var objectsArray = createCloudObjectArray(objectsArrayJSON, createObjectFunction);
    for (let i = 0; i < objectsArray.length; i++) {
        if (getIdFunction(objectsArray[i]) === id) {
            return objectsArray[i];
        }
    }
    return undefined;
}

export function addIsraelCountryCode(phone) {
    if (phone.startsWith("+"))
        return phone;
    else
        return "+972" + phone.substring(1);
}

export function getCurrentUser() {
    return getAuth().currentUser;
}

export function waitForUserStateToChange() {
    return new Promise((resolve, reject) => {
        getAuth().onAuthStateChanged(user => {
            resolve(user);
        })
    })
}

export function emailAndPasswordSignIn(email, password) {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
                console.log(userCredential.user);
                resolve(userCredential.user);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            })
    })
}

export function googleSignIn() {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        signInWithPopup(auth, provider).then(result => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                user.delete().then(() => {
                    reject('does not exist');
                }).catch(error => {
                    reject(error);
                    console.log(error);
                });
            } else {
                resolve(token);
            }
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export function isVipUser() {
    return new Promise((resolve, reject) => {
        const user = getCurrentUser();
        get(ref2(db, "users/" + user.uid + "/purchaseToken")).then(snapshot => {
            if (snapshot.val() === null)
                return false;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', 'https://smartcrm-vip-check-db346ffa741b.herokuapp.com/api/is_vip?purchaseToken=' + snapshot.val());
            xhr.onreadystatechange = function (e) {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    var response = JSON.parse(xhr.response);
                    if (response.isVip === 'True')
                        resolve(true);
                    else
                        resolve(false);
                }
            };
            xhr.setRequestHeader("Content-Type", "application/text");
            xhr.send(null);
        });
    });
}

export function loginAndroid() {
    const provider = new SAMLAuthProvider('saml.dev-6wuf5uwjg5lgq7ix.us.auth0.com');
    const auth = getAuth(app);
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider).then(result => {
            resolve(result.user.accessToken);
        });
    });
}

function getSubscriptionInfo(accessToken, purchaseToken, num) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        switch (num) {
            case 1:
                xhr.open('GET', 'https://androidpublisher.googleapis.com/androidpublisher/v3/applications/com.smart.crm/purchases/subscriptions/vip_monthly_1/tokens/' + purchaseToken);
                break;
            case 2:
                xhr.open('GET', 'https://androidpublisher.googleapis.com/androidpublisher/v3/applications/com.smart.crm/purchases/subscriptions/vip_annual_1/tokens/' + purchaseToken);
                break;
            case 3:
                xhr.open('GET', 'https://androidpublisher.googleapis.com/androidpublisher/v3/applications/com.smart.crm/purchases/subscriptions/vip_semiannual_1/tokens/' + purchaseToken);
                break;
            default:
        }
    });
}

export async function resetPassword(email) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
}

export async function addLabel(label) {
    var json = JSON.stringify(label);
    await uploadString(ref(storage, getCurrentUser().uid + "/labels/" + label.getLabelId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + label.getLabelId() + " Label Add");
}
export async function addImage(image) {
    console.log(image);
    var json = JSON.stringify(image);
    await uploadString(ref(storage, getCurrentUser().uid + "/images/" + image.getImageId()), json).catch(error => console.log(error));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + image.getImageId() + " Image Add").catch(error => console.log(error));
}
export async function addNote(note) {
    var json = JSON.stringify(note);
    await uploadString(ref(storage, getCurrentUser().uid + "/notes/" + note.getNoteId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + note.getNoteId() + " Note Add");
}
export async function addNotification(notification) {
    var json = JSON.stringify(notification);
    await uploadString(ref(storage, getCurrentUser().uid + "/notifications/" + notification.getNotificationId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + notification.getNotificationId() + " Notification Add");
}
export async function addUser(user) {
    var json = JSON.stringify(user);
    await uploadString(ref(storage, getCurrentUser().uid + "/users/" + user.getUserId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + user.getUserId() + " User Add");
}
export async function addAudioNote(audioNote, blob) {
    var json = JSON.stringify(audioNote);
    await uploadBytes(ref(storage, getCurrentUser().uid + "/audioFiles/" + audioNote.getFileLocation().split("/").pop()), blob);
    await uploadString(ref(storage, getCurrentUser().uid + "/audios/" + audioNote.getAudioNoteId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + audioNote.getAudioNoteId() + " Audio Add");
}
export async function addUserAndLabel(userAndLabel) {
    var json = JSON.stringify(userAndLabel);
    await uploadString(ref(storage, getCurrentUser().uid + "/userAndLabels/" + userAndLabel.getUserAndLabelId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + userAndLabel.getUserAndLabelId() + " UserAndLabel Add");
}
export async function addWhatsappSendLater(whatsappSendLater) {
    var json = JSON.stringify(whatsappSendLater);
    await uploadString(ref(storage, getCurrentUser().uid + "/whatsapps/" + whatsappSendLater.getWhatsappSendLaterId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + whatsappSendLater.getWhatsappSendLaterId() + " Whatsapp Add");
}
export async function addTemplateMessage(templateMessage) {
    var json = JSON.stringify(templateMessage);
    await uploadString(ref(storage, getCurrentUser().uid + "/templateMessages/" + templateMessage.getTemplateMessageId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + templateMessage.getTemplateMessageId() + " TemplateMessage Add");
}
export async function addCallLogInfo(callLogInfo) {
    var json = JSON.stringify(callLogInfo);
    await uploadString(ref(storage, getCurrentUser().uid + "/templateMessages/" + callLogInfo.getCallLogId()), json);
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + callLogInfo.getCallLogId() + " Call Add");
}

export async function deleteLabel(label) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/labels/" + label.getLabelId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + label.getLabelId() + " Label Delete");
}
export async function deleteImage(image) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/images/" + image.getImageId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + image.getImageId() + " Image Delete");
}
export async function deleteNote(note) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/notes/" + note.getNoteId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + note.getNoteId() + " Note Delete");
}
export async function deleteNotification(notification) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/notifications/" + notification.getNotificationId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + notification.getLabelId() + " Notification Delete");
}
export async function deleteUser(user) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/users/" + user.getUserId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + user.getUserId() + " User Delete");
}
export async function deleteAudioNote(audioNote) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/audios/" + audioNote.getAudioNoteId()));
    await deleteObject(ref(storage, getCurrentUser().uid + "/audioFiles/" + audioNote.getFileLocation().split("/").pop()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + audioNote.getAudioNoteId() + " Audio Delete");
}
export async function deleteUserAndLabel(userAndLabel) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/userAndLabels/" + userAndLabel.getUserAndLabelId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + userAndLabel.getUserAndLabelId() + " UserAndLabel Delete");
}
export async function deleteWhatsappSendLater(whatsappSendLater) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/whatsapps/" + whatsappSendLater.getWhatsappSendLaterId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + whatsappSendLater.getWhatsappSendLaterId() + " Whatsapp Delete");
}
export async function deleteTemplateMessage(templateMessage) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/templateMessages/" + templateMessage.getTemplateMessageId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + templateMessage.getTemplateMessageId() + " TemplateMessage Delete");
}
export async function deleteCallLogInfo(callLogInfo) {
    await deleteObject(ref(storage, getCurrentUser().uid + "/templateMessages/" + callLogInfo.getCallLogId()));
    await set(ref2(db, "users/" + getCurrentUser().uid + "/lastAction"), Date.now() + " " + callLogInfo.getCallLogId() + " Call Delete");
}

export async function getAllLabels() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/labels"));
    var labels = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        labels.push(createLabel(json));
    }
    return labels;
}
export async function getAllImages() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/images"));
    var images = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        images.push(createImage(json));
    }
    return images;
}
export async function getAllNotes() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/notes"));
    var notes = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        notes.push(createNote(json));
    }
    return notes;
}
export async function getAllNotifications() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/notifications"));
    var notifications = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        notifications.push(createNotification(json));
    }
    return notifications;
}

export async function getAllUsers() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/users"));
    var users = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        users.push(createUser(json));
    }
    return users;
}
export async function getAllAudioNotes() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/audios"));
    var audioNotes = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        audioNotes.push(createAudioNote(json));
    }
    return audioNotes;
}
export async function getAllAudioFiles() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/audioFiles"));
    var audioNotes = [];
    for (let i = 0; i < res.items.length; i++) {
        var blob = await getBlob(res.items[i]);
        var blobBuffer = await blob.arrayBuffer();
        let bytes = new Uint8Array(blobBuffer);
        let str = "";
        for (let j = 0; j < bytes.byteLength; j++) {
            str = str.concat(String.fromCharCode(bytes[j]));
        }
        var base64 = btoa(str);
        audioNotes.push(new AudioFile(base64, blob.type, res.items[i].name));
    }
    return audioNotes;
}
export async function getAllUserAndLabels() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/userAndLabels"));
    var userAndLabels = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        userAndLabels.push(createUserAndLabel(json));
    }
    return userAndLabels;
}
export async function getAllWhatsappSendLaters() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/whatsapps"));
    var whatsappSendLaters = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        whatsappSendLaters.push(createWhatsappSendLater(json));
    }
    return whatsappSendLaters;
}
export async function getAllTemplateMessages() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/templateMessages"));
    var templateMessages = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        templateMessages.push(createTemplateMessage(json));
    }
    return templateMessages;
}
export async function getAllCallLog() {
    var res = await listAll(ref(storage, getCurrentUser().uid + "/calls"));
    var callLogs = [];
    for (var i = 0; i < res.items.length; i++) {
        var bytes = await getBytes(res.items[i]);
        var json = new TextDecoder().decode(new Uint8Array(bytes));
        callLogs.push(createCallLogInfo(json));
    }
    callLogs.sort((b, a) => {
        return a.getDate() - b.getDate();
    });
    for (var j = 0; j < callLogs.length - 1;) {
        if (callLogs[j].getNumber() === callLogs[j + 1].getNumber()) {
            if (callLogs[j].getSameNumCount() === 0)
                callLogs[j].setSameNumCount(1);
            callLogs[j].setSameNumCount(callLogs[j].getSameNumCount() + 1);
            callLogs.splice(j + 1, 1);
        } else
            j++;
    }
    return callLogs;
}