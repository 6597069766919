import OvalLayout from "./OvalLayout";
import plus from '../assets/plus-round-icon.png';
import search from '../assets/search-icon.png';
import filter from '../assets/filter-line-icon.png';
import Popup from "reactjs-popup";
import { PopupContent } from "./PopupContent";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
export default function UpperBar(props) {
    var navigate = useNavigate();
    var [phone, setPhone] = useState("");
    var leadComponent = (<Popup trigger={<OvalLayout contentType={"text"} backgroundColor={"transparent"} text={"הוסף ליד חדש"} border icon={plus} />} position={"center center"} modal nested>{close => (<PopupContent onClose={close} content={<div><PhoneInput defaultCountry="IL" placeHolder={"טלפון..."} value={phone} onChange={setPhone} /></div>} onSave={() => { return new Promise((resolve, reject) => { localStorage.setItem('currentLeadNumber', phone); navigate('/addNewLead'); }) }} />)}</Popup>);
    if (props.isLead)
        leadComponent = (<OvalLayout contentType={"text"} backgroundColor={"transparent"} text={"הוסף ליד חדש"} border borderColor={"orange"} icon={plus} />)
    var labelComponent = (<OvalLayout contentType={"text"} border backgroundColor={"transparent"} text={"סינון"} icon={filter} onClick={() => { navigate('/LabelsFilter') }} />);
    if (props.isLabel)
        labelComponent = (<OvalLayout contentType={"text"} backgroundColor={"transparent"} text={"סינון"} border borderColor={"orange"} icon={filter} />);
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'fit-content(0) fit-content(0) fit-content(0)', gap: '20px', gridArea: props.gridArea, direction: 'rtl', padding: 15 }}>
            <OvalLayout contentType={"editText"} border backgroundColor={"transparent"} text={"חיפוש"} icon={search} onEnterClicked={props.onEnterClicked} />
            {labelComponent}
            {leadComponent}
        </div>
    );
}