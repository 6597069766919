import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import HomePage from "./pages/HomePage";
import CallLogPage from "./pages/CallLogPage";
import ContactsPage from "./pages/ContactsPage";
import TrackingPage from "./pages/TrackingPage";
import NotificationsPage from "./pages/NotificationsPage";
import WhatsappPage from "./pages/WhatsappPage";
import AccountPage from "./pages/AccountPage";
import NewLeadPage from "./pages/NewLeadPage";
import { useEffect } from "react";
import { listenForChanges } from "./cloud/CloudUtils";
import { useState } from "react";
import WhatsappTemplatesPage from "./pages/WhatsappTemplatesPage";
import LabelsFilterPage from "./pages/LabelsFilterPage";
import AndroidLoginPage from './pages/AndroidLoginPage';
function App() {
  useEffect(() => {
    listenForChanges();
  }, []);

  return (<div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/call-log" element={<CallLogPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/tracking" element={<TrackingPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/whatsapp" element={<WhatsappPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/addNewLead" element={<NewLeadPage />} />
        <Route path="/whatsappTemplates" element={<WhatsappTemplatesPage />} />
        <Route path="/LabelsFilter" element={<LabelsFilterPage />} />
        <Route path="/android-log-in" element={<AndroidLoginPage />} />
      </Routes>
    </BrowserRouter>
  </div>);
}

export default App;
