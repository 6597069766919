import { useState } from "react";
import Popup from "reactjs-popup";
import { PopupContent } from "./PopupContent";
import deleteIcon from '../assets/trash-can.png';

export default function DeletePopup(props) {
    return (
        <div dir="rtl" style={{ display: 'flex', alignItems: 'center' }}>
            {props.itemContent}
            <Popup trigger={<img src={deleteIcon} height={30} width={30} />} modal nested>{close => (<PopupContent onClose={close} onYes={props.deleteFunction} content={"האם אתה בטוח שברצונך למחוק את הפריט?"} alertText={"הפריט נמחק בהצלחה"} />)}</Popup>
        </div>
    );
}