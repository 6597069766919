import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import contacts from '../assets/contacts-icon.png';
import call from '../assets/ic_calls-icon.png';
import notebook from '../assets/notebook-icon.png';
import notification from '../assets/bell-icon.png';
import whatsapp from '../assets/whatsapp-icon.png';
import account from '../assets/account-icon.png';

function SelectedListItem(props) {
  const navigate = useNavigate();
  const HandleListItemClick = (index) => {
    var nextPage;
    switch (index) {
      case 0:
        nextPage = "../call-log";
        break;
      case 1:
        nextPage = "../contacts";
        break;
      case 2:
        nextPage = "../tracking";
        break;
      case 3:
        nextPage = "../notifications";
        break;
      case 4:
        nextPage = "../whatsapp";
        break;
      case 5:
        nextPage = "../account";
        break;
      default:
        break;
    }
    navigate(nextPage);
  };

  return (
    <Box sx={{ verticalAlign: 'top', width: '100%', maxWidth: 200, display: 'inline-block', direction: 'rtl' }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={props.index === 0}
          onClick={() => HandleListItemClick(0)}
        >
          <img src={call} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="יומן שיחות" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
        <ListItemButton
          selected={props.index === 1}
          onClick={() => HandleListItemClick(1)}
        >
          <img src={contacts} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="אנשי קשר" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
        <ListItemButton
          selected={props.index === 2}
          onClick={() => HandleListItemClick(2)}
        >
          <img src={notebook} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="במעקב" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
        <ListItemButton
          selected={props.index === 3}
          onClick={() => HandleListItemClick(3)}
        >
          <img src={notification} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="התזכורות שלי" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
        <ListItemButton
          selected={props.index === 4}
          onClick={() => HandleListItemClick(4)}
        >
          <img src={whatsapp} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="תזכורות לוואטסאפ" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
        <ListItemButton
          selected={props.index === 5}
          onClick={() => HandleListItemClick(5)}
        >
          <img src={account} height={20} width={20} style={{ marginLeft: '10px' }} />
          <ListItemText primary="החשבון שלי" style={{ color: 'white', textAlign: 'right' }} />
        </ListItemButton>
      </List>
    </Box>
  );
}
export default SelectedListItem;