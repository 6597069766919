export class Note {
    constructor(noteId, userCreatorId, timestamp, text) {
        this.noteId = noteId;
        this.userCreatorId = userCreatorId;
        this.timestamp = timestamp;
        this.text = text;
    }
    getNoteId() {
        return this.noteId;
    }
    getUserCreatorId() {
        return this.userCreatorId;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getText() {
        return this.text;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}