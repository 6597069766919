var firstAvailableIdInHoles;
var generatedIds;
const minIdNum = 1;
const maxIdNum = 9223372036854775807;

function generateUniqueLocalId() {
    try {
        initVariables();
        var availableId = Number(firstAvailableIdInHoles.values().next().value);
        makeSpecificIdUnavailable(availableId);
        return availableId;
    } catch (e) {
        throw new Error('All of the ids are currently used.');
    }
}

function makeSpecificIdUnavailable(id) {
    initVariables();
    firstAvailableIdInHoles.delete(String(id));
    var successorId = id + 1;
    if (successorId <= maxIdNum) {
        if (!generatedIds.has(String(successorId)))
            firstAvailableIdInHoles.add(String(successorId));
    }
    generatedIds.add(String(id));
    saveVariables();
}

export function onAppCreation() {
    if (localStorage.getItem("first") === null) {
        localStorage.setItem("first", JSON.stringify(false));
        onFirstAppCreation();
    }
}

function onFirstAppCreation() {
    firstAvailableIdInHoles = new Set();
    generatedIds = new Set();
    firstAvailableIdInHoles.add(String(minIdNum));
    saveVariables();
}

function freeLocalId(id) {
    var predecessorId = id - 1;
    if (predecessorId >= minIdNum) {
        if (generatedIds.has(String(predecessorId)))
            firstAvailableIdInHoles.add(String(id));
    }
    generatedIds.delete(String(id));
    saveVariables();
}

export function generateGlobalUniqueId() {
    var globallyUniqueInstallationId = JSON.parse(localStorage.getItem('firstToken'));
    return globallyUniqueInstallationId.concat(String(generateUniqueLocalId()));
}

export function freeId(id) {
    let localIdNum = getLocalIdNumber(id);
    if (localIdNum !== null)
        freeLocalId(localIdNum);
}

function getLocalIdNumber(id) {
    var globallyUniqueInstallationId = JSON.parse(localStorage.getItem('firstToken'));
    if (id.startsWith(globallyUniqueInstallationId)) {
        var localIdString = id.substring(globallyUniqueInstallationId.length());
        var localId = Number(localIdString);
        return localId;
    } else
        return null;
}

function initVariables() {
    if (firstAvailableIdInHoles === undefined || generatedIds === undefined) {
        firstAvailableIdInHoles = new Set();
        generatedIds = new Set();
        var firstAvailableIdInHolesJSON = JSON.parse(localStorage.getItem("firstAvailableIdInHoles"));
        var generatedIdsJSON = JSON.parse(localStorage.getItem("generatedIds"));
        for (var i = 0; i < firstAvailableIdInHolesJSON.length; i++) {
            firstAvailableIdInHoles.add(firstAvailableIdInHolesJSON[i]);
        }
        for (var j = 0; j < generatedIdsJSON.length; j++) {
            generatedIds.add(generatedIdsJSON[j]);
        }
    }
}

function saveVariables() {
    localStorage.setItem("firstAvailableIdInHoles", JSON.stringify(Array.from(firstAvailableIdInHoles)));
    localStorage.setItem("generatedIds", JSON.stringify(Array.from(generatedIds)));
}