import Page from "../components/Page";
import React from "react";
import { User } from "../cloud/User";
import { createCloudObjectArray, createNotification, createUser } from "../cloud/CloudUtils";
import NotificationListItem from "../components/NotificationListItem";
function NotificationPage() {
    var listWidth = 300;
    function createListItem(listObject) {
        let users = createCloudObjectArray("users", createUser);
        let user;
        for (let i = 0; i < users.length; i++) {
            if (users[i].getUserId() === listObject.getUserCreatorId()) {
                user = users[i];
                break;
            }
        }
        return (<NotificationListItem name={user.getUserName()} message={listObject.getText()} phoneNumber={user.getPhoneNumber()} date={new Date(listObject.getTimestamp()).toLocaleString('en-GB')} listWidth={listWidth}></NotificationListItem>);
    }
    function onListItemClick(listObject) {
        let user = new User(null, listObject.getName(), true, null, null, null, null, null, null, null, null, null, null, null, listObject.getNumber(), listObject.getPhoto());
        return user;
    }
    function getAllNotifications(showPastNotifications) {
        return new Promise((resolve, reject) => {
            var notifications = createCloudObjectArray('notifications', createNotification);
            if (!showPastNotifications) {
                for (let i = 0; i < notifications.length;) {
                    if (notifications[i].getTimestamp() < new Date().getTime())
                        notifications.splice(i, 1);
                    else
                        i++;
                }
            }
            notifications.sort((b, a) => {
                return b.getTimestamp() > a.getTimestamp();
            });
            resolve(notifications);
        })
    }

    function filter(listObject, text) {
        let users = createCloudObjectArray("users", createUser);
        let user;
        for (let i = 0; i < users.length; i++) {
            if (users[i].getUserId() === listObject.getUserCreatorId()) {
                user = users[i];
                break;
            }
        }
        if (listObject.getText() !== null && user.getUserName() !== null && user.getPhoneNumber() !== null)
            return listObject.getText().startsWith(text) || user.getUserName().startsWith(text) || user.getPhoneNumber().startsWith(text);
        else if (listObject.getText() !== null && user.getUserName() !== null)
            return listObject.getText().startsWith(text) || user.getUserName().startsWith(text);
        else if (user.getUserName() !== null && user.getPhoneNumber() !== null)
            return user.getUserName().startsWith(text) || user.getPhoneNumber().startsWith(text);
        else if (listObject.getText() !== null && user.getPhoneNumber() !== null)
            return listObject.getText().startsWith(text) || user.getPhoneNumber().startsWith(text);
        else if (user.getUserName() !== null)
            return user.getUserName().startsWith(text);
        else if (listObject.getText() !== null)
            return listObject.getText().startsWith(text);
        else if (user.getPhoneNumber() !== null)
            return user.getPhoneNumber().startsWith(text);
        return false;
    }

    return (
        <Page listFunction={getAllNotifications} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listWidth={listWidth} title={"התזכורות שלי"} reminder={true} listItemHeight={120} index={3} />
    );
}
export default NotificationPage;