import { useEffect, useState } from "react";
import { User } from "../cloud/User";
import Page from "../components/Page";
import { createCloudObjectArray, createUser, createCallLogInfo } from "../cloud/CloudUtils";
function NewLeadPage() {

    var [user, setUser] = useState(new User(null, null, null, null, null, null, null, null, null, null, null, null, null, null, localStorage.getItem("currentLeadNumber"), null));

    useEffect(() => {
        var users = createCloudObjectArray("users", createUser);
        for (var i = 0; i < users.length; i++) {
            if (users[i].getPhoneNumber() === localStorage.getItem("currentLeadNumber")) {
                setUser(users[i]);
                return;
            }
        }
        var contacts = createCloudObjectArray('contacts', createCallLogInfo);
        var contact;
        for (let i = 0; i < contacts.length; i++) {
            var currentNumber = localStorage.getItem("currentLeadNumber").trim().replaceAll('-', '').replaceAll('+', '+').replaceAll(' ', '');
            var contactNumber = contacts[i].getNumber().trim().replaceAll('-', '').replaceAll('+', '').replaceAll(' ', '');
            if (currentNumber.endsWith(contactNumber)) {
                contact = contacts[i];
                break;
            }
        }
        if (contact !== undefined)
            setUser(new User(null, contact.getName(), true, null, null, null, null, null, null, null, null, null, null, null, contact.getNumber(), contact.getPhoto()));
    }, []);

    return (
        <Page startingUser={user} listFunction={() => { return new Promise((resolve, reject) => { resolve([]) }) }} filter={(listObject, text) => { return false; }} createListItemFunction={(listObject) => { return null; }} onListItemClick={(listObject) => { return []; }} listWidth={0} listItemHeight={0} index={0} title={'הוספת ליד חדש'} hideList isLead />
    );
}
export default NewLeadPage