import Page from "../components/Page";
import React from "react";
import ContactListItem from "../components/ContactListItem";
import { User } from "../cloud/User";
import { createCallLogInfo, createCloudObjectArray, createUser } from "../cloud/CloudUtils";
function ContactsPage() {
    var listWidth = 300;
    function createListItem(listObject) {
        return (<ContactListItem listObject={listObject} listWidth={listWidth}></ContactListItem>);
    }
    function onListItemClick(listObject) {
        let number = listObject.getNumber();
        let userJsonObjects = JSON.parse(localStorage.getItem("users"));
        let user;
        for (let i = 0; i < userJsonObjects.length; i++) {
            let tmpUser = createUser(JSON.stringify(userJsonObjects[i]));
            if (tmpUser.getPhoneNumber() === number) {
                user = tmpUser;
                break;
            }
        }
        if (user === undefined)
            user = new User(null, listObject.getName(), true, null, null, null, null, null, null, null, null, null, null, null, listObject.getNumber(), listObject.getPhoto());
        return user;
    }
    function getAllContacts() {
        return new Promise((resolve, reject) => {
            var contacts = createCloudObjectArray('contacts', createCallLogInfo);
            contacts.sort((b, a) => {
                return b.getName().localeCompare(a.getName());
            });
            resolve(contacts);
        })
    }

    function filter(listObject, text) {
        if (listObject.getName() !== null && listObject.getNumber() !== null)
            return listObject.getName().startsWith(text) || listObject.getNumber().startsWith(text);
        else if (listObject.getName() !== null)
            return listObject.getName().startsWith(text)
        else if (listObject.getNumber() !== null)
            return listObject.getNumber().startsWith(text);
        return false;
    }

    return (
        <Page listFunction={getAllContacts} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listWidth={listWidth} title={"אנשי קשר"} listItemHeight={75} index={1} />
    );
}
export default ContactsPage;