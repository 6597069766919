import { createCallLogInfo, createCloudObjectArray, createLabel, createUser, createUserAndLabel } from "../cloud/CloudUtils";
import CloudList from "../components/CloudList";
import Page from "../components/Page";
import { useEffect, useState } from "react";
import UserData from "../components/UserData";
import UserListItem from "../components/UserListItem";

export default function LabelsFilterPage() {

    var listWidth = 300;
    var listItemHeight = 75;
    var [content, setContent] = useState(<div></div>);
    var [userData, setUserData] = useState(<div></div>);
    var [userListObjects, setUserListObjects] = useState(null);

    useEffect(() => {
        if (userListObjects !== null)
            refreshContent(userListObjects, userData);
    }, [userListObjects, userData]);

    function getLabels() {
        return new Promise((resolve, reject) => {
            var labels = createCloudObjectArray('labels', createLabel);
            resolve(labels);
        });
    }

    function createListItem(listObject) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: listWidth }}>
                <span style={{ display: 'inline-block' }}>{listObject.getText()}</span>
                <button style={{ borderRadius: 50, backgroundColor: "#" + listObject.getColor(), width: 30, height: 30 }} />
            </div >
        );
    }

    function getUsersForLabel(label) {
        let users = createCloudObjectArray('users', createUser);
        let userAndLabels = createCloudObjectArray('userAndLabels', createUserAndLabel);
        let usersForLabel = [];
        for (let i = 0; i < userAndLabels.length; i++) {
            if (userAndLabels[i].getLabelId() === label.getLabelId()) {
                for (let j = 0; j < users.length; j++) {
                    if (userAndLabels[i].getUserId() === users[j].getUserId()) {
                        usersForLabel.push(users[j]);
                        break;
                    }
                }
            }
        }
        return usersForLabel;
    }

    function createUserListItem(listObject) {
        return (<UserListItem listObject={listObject} listWidth={listWidth}></UserListItem>);
    }

    function onUserListItemClick(listObject) {
        refreshUserData(listObject);
        return listObject;
    }

    function changeListObjectsLocally(listObjects) {
        setUserListObjects(listObjects);
    }

    function refreshUserData(user) {
        let userData = <UserData user={user} onListObjectsChangedLocally={changeListObjectsLocally} listObjects={userListObjects} />;
        setUserData(userData);
        return userData;
    }

    function onListItemClick(listObject) {
        // TODO show a list of calls with this number
        let usersForLabel = getUsersForLabel(listObject);
        setUserListObjects(usersForLabel);
        return null;
    }

    function refreshContent(listObjects, userData) {
        setContent(<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20 }}><CloudList listObjects={listObjects} createListItemFunction={createUserListItem} onListItemClick={onUserListItemClick} listWidth={listWidth} itemHeight={listItemHeight} />{userData}</div>);
    }

    function filter(listObject, text) {
        return listObject.getText().startsWith(text);
    }
    return (
        <Page refreshContentObject={content} content={content} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listFunction={getLabels} title={"סינון רשומות לפי תוויות"} isLabel listItemHeight={listItemHeight} index={0} listWidth={listWidth} />
    );
}