export class WhatsappSendLater {
    constructor(whatsappSendLaterId, userCreatorId, contact, text, contactNumber, timestamp, displayFlag, workId, whatsappApplicationId) {
        this.whatsappSendLaterId = whatsappSendLaterId;
        this.userCreatorId = userCreatorId;
        this.contact = contact;
        this.text = text;
        this.contactNumber = contactNumber;
        this.timestamp = timestamp;
        this.displayFlag = displayFlag;
        this.workId = workId;
        this.whatsappApplicationId = whatsappApplicationId;
    }
    getWhatsappSendLaterId() {
        return this.whatsappSendLaterId;
    }
    getUserCreatorId() {
        return this.userCreatorId;
    }
    getContact() {
        return this.contact;
    }
    getText() {
        return this.text;
    }
    getContactNumber() {
        return this.contactNumber;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getDisplayFlag() {
        return this.displayFlag;
    }
    getWorkId() {
        return this.workId;
    }
    getWhatsappApplicationId() {
        return this.whatsappApplicationId;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}