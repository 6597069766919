export class CallLogInfo {
    constructor(callLogId, userId, labels, contactId, name, photo, number, callType, date, duration, sameNumCount, isContact) {
        this.callLogId = callLogId;
        this.userId = userId;
        this.labels = labels;
        this.contactId = contactId;
        this.name = name;
        this.photo = photo;
        this.number = number;
        this.callType = callType;
        this.date = date;
        this.duration = duration;
        this.sameNumCount = sameNumCount;
        this.isContact = isContact;
    }

    getCallLogId() {
        return this.callLogId;
    }

    getUserId() {
        return this.userId;
    }

    getLabels() {
        return this.labels;
    }

    getContactId() {
        return this.contactId;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getPhoto() {
        return this.photo;
    }

    getNumber() {
        return this.number;
    }

    getCallType() {
        return this.callType;
    }

    getDate() {
        return this.date;
    }

    getDuration() {
        return this.duration;
    }

    getSameNumCount() {
        return this.sameNumCount;
    }

    getIsContact() {
        return this.isContact;
    }

    setSameNumCount(sameNumCount) {
        this.sameNumCount = sameNumCount;
    }

    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }

}