import Page from "../components/Page";
import React from "react";
import { createUser, createCallLogInfo, createCloudObjectArray } from "../cloud/CloudUtils";
import CallLogListItem from "../components/CallLogListItem";
import { User } from "../cloud/User";


function CallLogPage() {
    var listWidth = 300;
    function createListItem(listObject) {
        return (<CallLogListItem listObject={listObject} listWidth={listWidth}></CallLogListItem>);
    }
    function onListItemClick(listObject) {
        let number = listObject.getNumber();
        let userJsonObjects = JSON.parse(localStorage.getItem("users"));
        let user;
        for (let i = 0; i < userJsonObjects.length; i++) {
            let tmpUser = createUser(JSON.stringify(userJsonObjects[i]));
            if (tmpUser.getPhoneNumber() === number) {
                user = tmpUser;
                break;
            }
        }
        if (user === undefined) {
            let users = createCloudObjectArray("users", createUser);
            let found = false;
            for (let i = 0; i < users.length; i++) {
                if (users[i].getPhoneNumber() === number) {
                    found = true;
                    break;
                }
            }
            user = new User(null, listObject.getName(), found, null, null, null, null, null, null, null, null, null, null, null, listObject.getNumber(), listObject.getPhoto());
        }
        return user;
    }

    function filter(listObject, text) {
        if (listObject.getName() !== null && listObject.getNumber() !== null)
            return listObject.getName().startsWith(text) || listObject.getNumber().startsWith(text);
        else if (listObject.getName() !== null)
            return listObject.getName().startsWith(text)
        else if (listObject.getNumber() !== null)
            return listObject.getNumber().startsWith(text);
        return false;
    }

    function getAllCallLog() {
        return new Promise((resolve, reject) => {
            var calls = createCloudObjectArray('calls', createCallLogInfo);
            var users = createCloudObjectArray('users', createUser);
            for (let i = 0; i < calls.length; i++) {
                for (let j = 0; j < users.length; j++) {
                    if (calls[i].getNumber() === users[j].getPhoneNumber()) {
                        calls[i].setName(users[j].getUserName());
                        break;
                    }
                }
            }
            resolve(calls);
        })
    }

    return (
        <Page listFunction={getAllCallLog} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listWidth={listWidth} title={"יומן שיחות"} listItemHeight={75} index={0} />
    );
}
export default CallLogPage;