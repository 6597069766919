import addIcon from '../assets/ic_add.png';
import infoIcon from '../assets/ic_info.png';
import Popup from 'reactjs-popup';
import { PopupContent } from './PopupContent';
export function UserPropertyItem(props) {
    var addPopupHeight = 'auto';
    if (props.addPopupHeight !== undefined)
        addPopupHeight = props.addPopupHeight;
    return (
        <div style={{ width: props.contentWidth + 'px', boxSizing: 'border-box', border: '3px solid gray' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 5fr', gap: '10px', padding: '5px' }}>
                <Popup trigger={<img src={addIcon} height={20} width={20} />} position={"center center"} modal nested>{close => (<PopupContent height={addPopupHeight} onClose={close} onSave={props.onSave} content={props.addPopupContent(close)} />)}</Popup>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr' }}>
                    <Popup trigger={<img src={infoIcon} height={20} width={20} />} position={"center center"} modal nested>{close => (<PopupContent onClose={close} content={props.infoText} />)}</Popup>
                    <span dir='rtl' style={{ width: '100%' }}>{props.title}</span>
                </div>
            </div >
            <div>
                {props.content}
            </div>
        </div>
    );
}