export class Image {
    constructor(imageId, imageCompressedData, userCreatorId, updateDate, albumFile) {
        this.imageId = imageId;
        this.imageCompressedData = imageCompressedData;
        this.userCreatorId = userCreatorId;
        this.updateDate = updateDate;
        this.albumFile = albumFile;
    }
    getImageId() {
        return this.imageId;
    }
    getImageCompressedData() {
        return this.imageCompressedData;
    }
    getUserCreatorId() {
        return this.userCreatorId;
    }
    getUpdateDate() {
        return this.updateDate;
    }
    getAlbumFile() {
        return this.albumFile;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}