export class Label {
    constructor(labelId, timestamp, text, color) {
        this.labelId = labelId;
        this.timestamp = timestamp;
        this.text = text;
        this.color = color;
    }
    getLabelId() {
        return this.labelId;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getText() {
        return this.text;
    }
    getColor() {
        return this.color;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}