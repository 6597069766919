import { useEffect } from "react";
import { FixedSizeList } from "react-window";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { createCloudObjectArray, createLabel, createUserAndLabel } from "../cloud/CloudUtils";
export function LabelsList(props) {
    var [labels, setLabels] = useState(undefined);
    var [checkedLabels, setCheckedLabels] = useState([]);
    useEffect(() => {
        var labelObjects = createCloudObjectArray("labels", createLabel);
        setLabels(labelObjects);
        var userAndLabelObjects = createCloudObjectArray("userAndLabels", createUserAndLabel);
        var tempCheckedLabels = [];
        for (var t = 0; t < labelObjects.length; t++) {
            for (var k = 0; k < userAndLabelObjects.length; k++) {
                if (userAndLabelObjects[k].getLabelId() === labelObjects[t].getLabelId() && userAndLabelObjects[k].getUserId() === props.user.getUserId()) {
                    tempCheckedLabels.push(labelObjects[t]);
                    break;
                }
            }
        }
        setCheckedLabels(tempCheckedLabels);
    }, []);

    function renderRow(props2) {
        const { index, style } = props2;
        return (
            <div style={style} key={index}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    {<span style={{ color: "#" + labels[index].getColor() }}>{labels[index].getText()}</span>}{<Checkbox checked={checkedLabels.includes(labels[index])} onChange={(e) => { var tempCheckedLabels = []; for (var i = 0; i < checkedLabels.length; i++) tempCheckedLabels.push(checkedLabels[i]); if (tempCheckedLabels.includes(labels[index])) tempCheckedLabels.splice(tempCheckedLabels.indexOf(labels[index]), 1); else tempCheckedLabels.push(labels[index]); setCheckedLabels(tempCheckedLabels); props.onLabelChange(labels[index], e.target.checked); }} />}
                </div>
            </div>
        );
    }
    if (labels !== undefined) {
        return (
            <FixedSizeList
                height={200}
                width={200}
                itemSize={75}
                itemCount={labels.length}
                overscanCount={5}
            >
                {renderRow}
            </FixedSizeList>
        );
    } else
        return (<div></div>);
}