import defaultContactImage from '../assets/ic_account.png';
export default function UserListItem(props) {
    var padding = 10;
    var profilePhoto;
    if (props.listObject.getMainImage() !== null) {
        profilePhoto = props.listObject.getMainImage();
    }
    var imgSrc;
    if (profilePhoto !== undefined) {
        imgSrc = "data:image/png;base64," + profilePhoto;
    } else
        imgSrc = defaultContactImage;
    var name;
    if (props.listObject.getUserName() === null) {
        name = props.listObject.getPhoneNumber();
    } else {
        name = props.listObject.getUserName();
    }
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr', width: (props.listWidth - 2 * padding) + 'px', padding: padding + 'px' }}>
            <div dir='rtl' style={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr', rowGap: '0px' }}>
                <span dir='rtl' style={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'right' }}>{name}</span>
                <span dir='rtl' style={{ fontSize: '10px', textAlign: 'right' }}>{props.listObject.getPhoneNumber()}</span>
                <span dir='rtl' style={{ fontSize: '10px', textAlign: 'right' }}>{new Date(props.listObject.getLastActionDate()).toLocaleString('en-GB')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={imgSrc} height={40} width={40} />
            </div>
        </div>
    );
}