export class AudioFile {
    constructor(blobText, blobType, location) {
        this.blobText = blobText;
        this.blobType = blobType;
        this.location = location;
    }

    getBlobText() {
        return this.blobText;
    }
    getBlobType() {
        return this.blobType;
    }
    getLocation() {
        return this.location;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}