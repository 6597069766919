import NavList from "../components/NavList";
import CloudList from "./CloudList";
import { waitForUserStateToChange, setCurrentRefreshFunction } from "../cloud/CloudUtils";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import logo from "../assets/smartcrm-logo.png";
import UpperBar from "../components/UpperBar";
import Loader from "./Loader";
import UserData from "./UserData";
import { Switch } from "@mui/material";
export default function Page(props) {
    var navigate = useNavigate();
    var [listObjects, setListObjects] = useState(null);
    var [loading, setLoading] = useState(true);
    var [content, setContent] = useState(<div></div>);
    var [showPastReminders, setShowPastReminders] = useState(false);
    var [cloudList, setCloudList] = useState(<div></div>);
    var [refresh, setRefresh] = useState(false);
    var currentUser = useRef(null);
    var currentText = useRef("");

    useEffect(() => {
        createAndSetAllFilteredListObjects(currentText.current);
        recreateUserData(currentUser.current);
        var refreshFunction = () => { setRefresh(!refresh); };
        setCurrentRefreshFunction(refreshFunction);
        return () => setCurrentRefreshFunction(undefined);
    }, [refresh]);

    useEffect(() => {
        waitForUserStateToChange().then((user) => {
            if (user == null)
                navigate("../");
            else {
                createAndSetAllFilteredListObjects();
            }
        });
    }, []);

    useEffect(() => {
        if (listObjects != null) {
            recreateCloudList(listObjects);
        }
    }, [listObjects, props.refreshListObject]);

    useEffect(() => {
        recreateUserData(null);
    }, [props.refreshContentObject]);

    useEffect(() => {
        if (props.startingUser !== undefined && listObjects !== null)
            recreateUserData(props.startingUser);
    }, [props.startingUser]);

    useEffect(() => {
        if (listObjects != null)
            createAndSetAllFilteredListObjects();
    }, [showPastReminders]);

    function onEnterClicked(text) {
        createAndSetAllFilteredListObjects(text);
    }

    async function createAndSetAllFilteredListObjects(text) {
        if (text === undefined)
            text = "";
        currentText.current = text;
        setLoading(true);
        var newListObjects = [];
        executeListCreationFunction().then((listObjects) => {
            console.log(listObjects + " page");
            for (var i = 0; i < listObjects.length; i++) {
                console.log(listObjects[i] + " page");
                if (props.filter(listObjects[i], text)) {
                    newListObjects.push(listObjects[i]);
                }
            }
            changeListObjectsLocally(newListObjects);
            setLoading(false);
        });
    }

    async function executeListCreationFunction() {
        if (props.reminder !== undefined)
            return await props.listFunction(showPastReminders);
        else
            return await props.listFunction();
    }

    function recreateCloudList(listObjects) {
        if (props.hideList === undefined)
            setCloudList(<CloudList gridArea={'contentList'} listObjects={listObjects} createListItemFunction={props.createListItemFunction} onListItemClick={onListItemClick} listWidth={props.listWidth} itemHeight={props.listItemHeight} />);
    }

    function changeListObjectsLocally(listObjects) {
        setListObjects(listObjects);
    }

    function recreateUserData(user) {
        if (user !== null) {
            currentUser.current = user;
            setContent(<UserData refreshObject={refresh} extraRefreshFunctions={props.extraRefreshFunctions} user={currentUser.current} onListObjectsChangedLocally={changeListObjectsLocally} listObjects={listObjects}></UserData>);
        } else if (props.content !== undefined)
            setContent(props.content);
        else
            setContent(<div></div>);
    }

    function onListItemClick(listObject) {
        let user = props.onListItemClick(listObject);
        recreateUserData(user);
    }
    var templateAreas = `'upperBar upperBar navList' 'title title navList' 'content contentList navList'`;
    if (props.reminder !== undefined)
        templateAreas = `'upperBar upperBar navList' 'title title navList' 'checkbox checkbox navList' 'content contentList navList'`;
    var checkbox;
    if (props.reminder !== undefined) {
        checkbox = (
            <div dir="rtl" style={{ gridArea: 'checkbox' }}>
                <Switch checked={showPastReminders} onChange={(e) => { setShowPastReminders(e.target.checked) }} />
                הצג גם הודעות מתאריך ושעה שעברו
            </div>
        );
    }

    if (loading)
        return (<Loader />)
    else {
        return (
            <div>
                <div style={{ display: 'grid', gridTemplateAreas: templateAreas, gridTemplateColumns: 'auto ' + props.listWidth + 'px' + ' 200px', gridTemplateRows: 'fit-content(0) 40px auto', height: '100svh' }}>
                    <UpperBar gridArea={'upperBar'} onEnterClicked={onEnterClicked} isLead={props.isLead} isLabel={props.isLabel} />
                    <span dir="rtl" style={{ gridArea: 'title', backgroundColor: 'gray', color: 'white', fontWeight: 'bold', fontSize: '25px', height: '40px' }}>{props.title}</span>
                    <div dir="rtl" style={{ gridArea: 'content' }}>{content}</div>
                    {checkbox}
                    {cloudList}
                    <div style={{ backgroundColor: 'black', textAlign: 'center', gridArea: 'navList' }}>
                        <img src={logo} height={70} width={70} /><br />
                        <span style={{ color: 'white', fontWeight: 'bold' }}>Smart CRM</span>
                        <NavList index={props.index} />
                    </div>
                </div>
            </div>
        );
    }
}