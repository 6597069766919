import Page from "../components/Page";
import whatsappIcon from '../assets/ic_whatsapp.png';
import { addUser, addWhatsappSendLater, createCloudObjectArray, createTemplateMessage, createUser } from "../cloud/CloudUtils";
import { useEffect, useRef, useState } from "react";
import { generateGlobalUniqueId } from "../cloud/IdGeneration";
import dayjs from "dayjs";
import ChooseTimePopupContent from "../components/ChooseTimePopupContent";
import Popup from "reactjs-popup";
import { PopupContent } from "../components/PopupContent";
import { WhatsappSendLater } from "../cloud/WhatsappSendLater";

export default function WhatsappTemplatesPage() {
    var listWidth = 300;
    var [user, setUser] = useState(null);
    var [date, setDate] = useState(dayjs());
    var [openTime, setOpenTime] = useState(false);
    var [openDetails, setOpenDetails] = useState(false);
    var [openSaveDetails, setOpenSaveDetails] = useState(false);
    var [refreshList, setRefreshList] = useState(false);
    var currentPopupContent = useRef(<div></div>);
    var currentMessageTextFunction = useRef(undefined);
    useEffect(() => {
        console.log(localStorage.getItem("whatsappTemplateUser"));
        setUser(createUser(localStorage.getItem("whatsappTemplateUser")));
    }, []);

    useEffect(() => {
        setRefreshList(!refreshList);
    }, [openTime, user]);

    function getWhatsappTemplates() {
        return new Promise((resolve, reject) => {
            var whatsappTemplates = createCloudObjectArray('templateMessages', createTemplateMessage);
            resolve(whatsappTemplates);
        });
    }

    async function dealWithUser() {
        user.setLastActionDate(new Date().getTime());
        if (user.getUserId() === null) {
            user.setUserId(generateGlobalUniqueId());
        }
        await addUser(user);
    }

    function createListItem(listObject) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: listWidth }}>
                <span>{listObject.getTitle()}</span>
                <img src={whatsappIcon} height={20} width={20} />
            </div >
        );
    }

    function onListItemClick(listObject) {
        let braceletTexts = [];
        let currentIndex = 0;
        while (true) {
            let nextBraceletStartIndex = listObject.getText().substring(currentIndex).indexOf("{");
            if (nextBraceletStartIndex === -1)
                break;
            currentIndex = currentIndex + nextBraceletStartIndex;
            let nextBraceletEndIndex = currentIndex + listObject.getText().substring(currentIndex).indexOf("}");
            let braceletText = listObject.getText().substring(currentIndex, nextBraceletEndIndex + 1);
            currentIndex = nextBraceletEndIndex + 1;
            braceletTexts.push(braceletText);
        }
        let inputs = [];
        for (let i = 0; i < braceletTexts.length; i++) {
            inputs.push(<input placeholder={braceletTexts[i]} id={"inputField_" + i} />);
            inputs.push(<br />);
        }
        currentPopupContent.current = <div>{inputs}</div>;
        currentMessageTextFunction.current = () => {
            let message = listObject.getText();
            for (let i = 0; i < braceletTexts.length; i++) {
                message = message.replace(braceletTexts[i], document.getElementById("inputField_" + i).value);
            }
            return message;
        }
        if (JSON.parse(localStorage.getItem('isWhatsappReminder')) === false)
            setOpenDetails(true);
        else
            setOpenTime(true);
        return null;
    }

    function filter(listObject, text) {
        return listObject.getTitle().startsWith(text) || listObject.getText().startsWith(text);
    }

    return (
        <div>
            <Page refreshListObject={refreshList} listFunction={getWhatsappTemplates} createListItemFunction={createListItem} onListItemClick={onListItemClick} filter={filter} listWidth={listWidth} title={"הודעות וואטסאפ מוכנות מתבנית"} listItemHeight={75} index={0} />
            <Popup open={openTime} position={"center center"} modal nested><PopupContent onSave={async () => { setOpenSaveDetails(true); setOpenTime(false); }} onClose={() => setOpenTime(false)} content={<ChooseTimePopupContent date={date} setDate={setDate} />} /></Popup>
            <Popup open={openDetails} position={"center center"} modal nested><PopupContent onSave={async () => { window.open('https://wa.me/' + user.getPhoneNumber() + "?text=" + currentMessageTextFunction.current(), "_blank").focus(); setOpenDetails(false); }} onClose={() => setOpenDetails(false)} content={currentPopupContent.current} /></Popup>
            <Popup open={openSaveDetails} position={"center center"} modal nested><PopupContent onSave={async () => { await dealWithUser(); await addWhatsappSendLater(new WhatsappSendLater(generateGlobalUniqueId(), user.getUserId(), user, currentMessageTextFunction.current(), user.getPhoneNumber(), date.toDate().getTime(), null, null, null)); setOpenSaveDetails(false); }} onClose={() => setOpenSaveDetails(false)} content={currentPopupContent.current} /></Popup>
        </div>
    );
}