export class UserAndLabel {
    constructor(userId, labelId, userAndLabelId) {
        this.userId = userId;
        this.labelId = labelId;
        this.userAndLabelId = userAndLabelId;
    }
    getUserId() {
        return this.userId;
    }
    getLabelId() {
        return this.labelId;
    }
    getUserAndLabelId() {
        return this.userAndLabelId;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}