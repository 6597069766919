export function PopupContent(props) {
    var height = 'auto';
    var width = "250px";
    if (props.width !== undefined)
        width = props.width;
    if (props.height !== undefined)
        height = props.height;
    if (props.onSave === undefined) {
        if (props.onYes === undefined) {
            return (
                <div dir='rtl' style={{ width: width, textAlign: 'center', background: 'white', border: '3px solid black', padding: '5px', height: height }}>{props.content}<br /><button onClick={props.onClose} style={{ border: 'none', color: 'orange', fontWeight: 'bold' }}>סגור</button></div>
            );
        } else {
            return (
                <div dir='rtl' style={{ width: '250px', textAlign: 'center', background: 'white', border: '3px solid black', padding: '5px', height: height }}>{props.content}<br /><button onClick={props.onClose} style={{ border: 'none', color: 'orange', fontWeight: 'bold' }}>סגור</button> <button onClick={() => { props.onYes().then(() => { props.onClose(); }); }} style={{ border: 'none', color: 'orange', fontWeight: 'bold' }}>כן</button></div>
            )
        }
    } else {
        return (
            <div dir='rtl' style={{ width: '250px', textAlign: 'center', background: 'white', border: '3px solid black', padding: '5px', height: height }}>{props.content}<br /><button onClick={props.onClose} style={{ border: 'none', color: 'orange', fontWeight: 'bold' }}>סגור</button> <button onClick={() => { props.onSave().then(() => { props.onClose(); }); }} style={{ border: 'none', color: 'orange', fontWeight: 'bold' }}>שמור</button></div>
        );
    }
}