import * as React from 'react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { FixedSizeList } from 'react-window';

export default function CloudList(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const HandleListItemClick = (index) => {
    setSelectedIndex(index);
    props.onListItemClick(props.listObjects[index]);
  };

  function renderRow(props2) {
    const { index, style } = props2;
    return (
      <ListItemButton style={style} key={index} selected={selectedIndex === index} onClick={() => HandleListItemClick(index)} component="div" disableGutters>
        {props.createListItemFunction(props.listObjects[index])}
      </ListItemButton>
    );
  }

  return (
    <Box
      sx={{ width: props.listWidth, height: 400, bgcolor: 'background.paper', display: 'inline-block', gridArea: props.gridArea }}
    >
      <FixedSizeList
        height={400}
        width={props.listWidth}
        itemSize={props.itemHeight}
        itemCount={props.listObjects.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
}