import { useState } from 'react';
export default function OvalLayout(props) {
    var contentElement;
    var height = 15;
    var padding = 8;
    var borderWidth = 0;
    var borderRadius = 40;
    if (props.height !== undefined) {
        switch (props.height) {
            case 'short':
                height = 10;
                break;
            default:
                height = 15;
        }
    }
    var totalHeight = height + 2 * padding;
    var width = '100px';
    var color;
    var borderColor;
    function onKeyUp(e) {
        if (e.key === 'Enter') {
            props.onEnterClicked(e.target.value);
        }
    }

    if (props.border) {
        borderWidth = 3;
        totalHeight += 2 * borderWidth;
    }
    color = props.textColor;
    if (color === undefined)
        color = "black";
    borderColor = props.borderColor;
    if (borderColor === undefined)
        borderColor = "black";
    if (props.borderRadius !== undefined) {
        borderRadius = props.borderRadius;
    }
    if (props.width !== undefined) {
        switch (props.width) {
            case 'widest':
                width = '250px';
                break;
            case 'wider':
                width = '200px';
                break;
            case 'wide':
                width = '150px';
                break;
            case 'narrow':
                width = '50px';
                break;
            default:
                width = '100px';
        }
    }
    switch (props.contentType) {
        case 'editText':
            contentElement = (<input type="text" placeholder={props.text} dir="rtl" style={{ color: color, height: height + "px", fontSize: height + "px", width: width, margin: 0, borderColor: 'transparent', padding: 0 }} onKeyUp={(e) => { onKeyUp(e) }} />);
            break;
        case 'text':
            if (props.icon !== undefined)
                contentElement = (<span dir="rtl" style={{ color: color, textAlign: 'right', height: height + "px", fontSize: height + "px", backgroundColor: "transparent", width: width, padding: 0 }}>{props.text}</span>);
            else
                contentElement = (<span dir="rtl" style={{ color: color, textAlign: 'center', height: height + "px", fontSize: height + "px", backgroundColor: "transparent", width: width, padding: 0 }}>{props.text}</span>);
            break;
        default:
            break;
    }

    if (props.icon !== undefined) {
        return (
            <button style={{ border: borderWidth + 'px', borderRadius: borderRadius + 'px', backgroundColor: props.backgroundColor, borderColor: borderColor, borderStyle: 'solid', display: 'inline-grid', gap: '5px', gridTemplateColumns: 'fit-content(0) auto', height: totalHeight, width: 'max-content', padding: padding, verticalAlign: "middle", boxSizing: 'border-box' }} onClick={props.onClick}>
                <img src={props.icon} style={{ aspectRatio: '1/1', height: height, padding: 0 }} />
                {contentElement}
            </button >
        );
    } else {
        return (
            <button type="button" style={{ border: borderWidth + 'px', borderRadius: borderRadius + 'px', backgroundColor: props.backgroundColor, borderColor: borderColor, borderStyle: 'solid', display: 'inline-grid', gap: '5px', gridTemplateColumns: 'auto', height: totalHeight, width: 'max-content', padding: padding, verticalAlign: "middle", boxSizing: 'border-box' }} onClick={props.onClick}>
                {contentElement}
            </button >
        );
    }
}