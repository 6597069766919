export class Notification {
    constructor(notificationId, userCreatorId, text, title, timestamp) {
        this.notificationId = notificationId;
        this.userCreatorId = userCreatorId;
        this.text = text;
        this.title = title;
        this.timestamp = timestamp;
    }
    getNotificationId() {
        return this.notificationId;
    }
    getUserCreatorId() {
        return this.userCreatorId;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getText() {
        return this.text;
    }
    getTitle() {
        return this.title;
    }
    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }
}