import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import OvalLayout from "./OvalLayout";
import ChooseTimePopupContent from "./ChooseTimePopupContent";
import { PopupContent } from "./PopupContent";
export default function WhatsappReminderPopupContent(props) {
    var navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}><Popup trigger={<OvalLayout contentType={"text"} backgroundColor={"orange"} text={"הודעה חדשה"} width={"wider"} borderRadius={6} />} position={"center center"} modal nested>{close => (<PopupContent onSave={props.onSave} height={200} onClose={close} content={<ChooseTimePopupContent close={close} date={props.date} setDate={props.setDate} />} />)}</Popup><OvalLayout contentType={"text"} backgroundColor={"orange"} text={"הודעה מוכנה מתבנית"} width={"wider"} borderRadius={6} onClick={() => { localStorage.setItem('whatsappTemplateUser', JSON.stringify(props.user)); localStorage.setItem('isWhatsappReminder', JSON.stringify(true)); navigate("/whatsappTemplates"); }} /></div>
    );
}