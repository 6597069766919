export default function NotificationListItem(props) {
    var padding = 10;
    var border = 3;
    return (
        <div dir="rtl" style={{ width: (props.listWidth - 2 * padding - 2 * border) + 'px', textAlign: 'right', padding: padding + 'px', border: border + 'px solid black' }}>
            {props.name} <br />
            {props.message} <br />
            {props.phoneNumber} <br />
            תאריך: {props.date}
        </div>
    );
}