import { useEffect, useState } from "react";
import logo from "../assets/smartcrm-logo.png";
import NavList from "../components/NavList";
import { logout, waitForUserStateToChange } from "../cloud/CloudUtils";
import OvalLayout from "../components/OvalLayout";
import { useNavigate } from "react-router-dom";
function AccountPage() {
    var navigate = useNavigate();
    var [email, setEmail] = useState("");

    useEffect(() => {
        waitForUserStateToChange().then((user) => {
            if (user == null)
                navigate("../");
            else
                setEmail(user.email);
        });
    }, []);

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateAreas: `'title navList' 'content navList'`, gridTemplateColumns: 'auto' + ' 200px', gridTemplateRows: '40px auto', height: '100svh' }}>
                <span dir="rtl" style={{ gridArea: 'title', backgroundColor: 'gray', color: 'white', fontWeight: 'bold', fontSize: '25px', height: '40px' }}>החשבון שלי</span>
                <div dir="rtl" style={{ gridArea: 'content', textAlign: 'center' }}>
                    <div style={{ width: '400px' }}>
                        <span style={{ fontSize: '25px' }}>המייל שלך:</span> <br />
                        <span>{email}</span><br /><br />
                        <span>סוג המנוי שלך:</span><br />
                        <span>מנוי vip</span><br /><br />
                        <a href="https://play.google.com/store/account/subscriptions">ניתן לבטל את המנוי בכל עת דרך Google Play</a><br /><br /><br />
                        <OvalLayout contentType={"text"} backgroundColor={"black"} textColor={"white"} text={"התנתק"} onClick={() => { localStorage.clear(); logout(); navigate('/'); }} />
                    </div>
                </div>
                <div style={{ backgroundColor: 'black', textAlign: 'center', gridArea: 'navList' }}>
                    <img src={logo} height={70} width={70} /><br />
                    <span style={{ color: 'white', fontWeight: 'bold' }}>Smart CRM</span>
                    <NavList index={5} />
                </div>
            </div>
        </div>
    );
}
export default AccountPage;