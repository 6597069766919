export class TemplateMessage {
    constructor(templateMessageId, title, text) {
        this.templateMessageId = templateMessageId;
        this.title = title;
        this.text = text;
    }

    getTemplateMessageId() {
        return this.templateMessageId;
    }

    getTitle() {
        return this.title;
    }

    getText() {
        return this.text;
    }

    fromJson(json) {
        Object.assign(this, JSON.parse(json));
    }

}